import Swal from "sweetalert2";
import apiAxios from "../../axios.js";
import withReactContent from "sweetalert2-react-content";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import ShowAlert from "./Alert.jsx";
import { FinancierConfirmActions } from "../strings.jsx";
import { isNotEmpty } from "../helpers/isEmptyHelpers.jsx";
import formatCurrency from "../helpers/formatCurrency.jsx";
import formatDate from "../helpers/formatDate.jsx";
import formatPercentage from "../helpers/formatPercentage.jsx";

library.add(fas);

export default function InvoiceActionAlert(invoice, action, callBack) {
  const unknownError = "An unknown error occurred";

  const showInvoiceDetails = () => {
    return (
      <div className="card form-content mt-4">
        <div className="card-body p-0 text-start">
          <div className="row px-4">
            <div className="col-md-6">                  
              <label htmlFor="financier">Original Amount:</label>
              <p>{ formatCurrency(invoice.amount, invoice.currency) }</p>
            </div>
            <div className="col-md-6">                  
              <label htmlFor="anticipatedValue">Anticipated value:</label>
              <p>{ `${formatCurrency(invoice.anticipated_amount, invoice.currency)} (${formatPercentage(invoice.percentage_requested)})` }</p>
            </div>
          </div>
          <div className="row px-4">
            <div className="col-md-6">                  
              <label htmlFor="buyer">Buyer:</label>
              <p>{ isNotEmpty(invoice.program?.buyer) ? invoice.program.buyer.display_name : "No Buyer Assigned" }</p>
            </div>
            <div className="col-md-6">                  
              <label htmlFor="dueDate">Due date:</label>
              <p>{ isNotEmpty(invoice.due_at) ? formatDate(invoice.due_at) : "Due Date Not Available" }</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showSwalConfirm = () => {
    withReactContent(Swal).fire({
      title: "Are you sure you want to confirm this early payment request?",
      icon: "question",
      iconColor: "grey",
      html: showInvoiceDetails(),
      backdrop: "rgba(0,0,0,0.4)",
      showCancelButton: true,
      reverseButtons: true,
      buttonsStyling: false,
      confirmButtonText: "Yes, confirm"
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("invoice_pk", invoice.pk);
        formData.append("status", FinancierConfirmActions.approve);

        apiAxios
          .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/financier/program/invoices/respond`, formData)
          .then((response) => {
            if (isNotEmpty(response.data)) {
              ShowAlert("success", response?.data?.message);
              callBack();
            } else {
              ShowAlert("warning", response?.error);
            }
          })
          .catch((error) => {
            var errorResponse = error.response?.data;
            ShowAlert("error", errorResponse?.error || unknownError);
          });
      }
    });
  };

  const showSwalDecline = () => {
    withReactContent(Swal).fire({
      title: "Are you sure you want to deny this request?",
      icon: "warning",
      iconColor: "red",
      html: showInvoiceDetails(),
      input: "textarea",
      inputLabel: "Deny reason",
      inputPlaceholder: "(Optional) Write a reason for denying this request...",
      inputAttributes: {
        "aria-label": "(Optional) Write a reason for denying this request..."
      },
      backdrop: "rgba(0,0,0,0.4)",
      showCancelButton: true,
      reverseButtons: true,
      buttonsStyling: false,
      confirmButtonText: "Yes, deny",
      customClass: {
        confirmButton: "swal2-confirm-danger",
        input: "swal2-input-textarea"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("invoice_pk", invoice.pk);
        formData.append("status", FinancierConfirmActions.decline);
        formData.append("reason", result.value);

        apiAxios
          .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/financier/program/invoices/respond`, formData)
          .then((response) => {
            if (isNotEmpty(response.data)) {
              ShowAlert("success");
              callBack();
            } else {
              ShowAlert("warning", response?.error);
            }
          })
          .catch((error) => {
            var errorResponse = error.response?.data;
            ShowAlert("error", errorResponse?.error || unknownError);
          });
      }
    });
  };

  const handleAlert = (action) => {
    switch (action) {
      case "confirm":
        showSwalConfirm();
        break;
      case "deny":
        showSwalDecline();
        break;
      default:
        ShowAlert("error", "Invalid action");
    }
  };

  return handleAlert(action);
}
