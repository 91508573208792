import ShowAlert from "../utils/Alert.jsx";

const apiWrapper = (apiCall) => {
  const unknownError = "An unknown error occurred.";
  return promiseWrapper(
    apiCall()
      .then(response => {
        return response;
      })
      .catch(error => {
        const errorResponse = error.response?.data;
        ShowAlert("error", errorResponse?.error || unknownError);
      })
  );
};

function promiseWrapper(promise) {
  let status = "pending";
  let result;
  const suspender = promise.then(
    (value) => {
      status = "success";
      result = value;
    },
    (error) => {
      status = "error";
      result = error;
    }
  );

  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
}

export { apiWrapper };
