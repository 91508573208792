import { Menu } from "primereact/menu";
import { Badge } from "primereact/badge";

const LeftMenu = () => {
  const itemRenderer = (item) => (
    <div className="p-menuitem-content">
      <a href={item.url} className="flex align-items-center p-menuitem-link">
        <span className={item.icon} />
        <span className="mx-2">{item.label}</span>
        {item.badge && <Badge className="ml-auto" value={item.badge} />}
      </a>
    </div>
  );
  const items = [
    {
      label: "Administrator",
      items: [
        {
          label: "Programs",
          icon: "pi pi-plus",
          url: "/admin/programs",
          template: itemRenderer
        },
        {
          label: "Suppliers",
          icon: "pi pi-search",
          url: "/admin/suppliers",
          template: itemRenderer
        }
      ]
    },
  ];

  return (
    <div className="col-2">
      <Menu model={items} className="w-full md:w-15rem" />
    </div>
  );
};

export default LeftMenu;
