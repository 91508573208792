import { useState } from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { Controller, useForm } from "react-hook-form";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import { isNotEmpty } from "../../helpers/isEmptyHelpers.jsx";
import ShowAlert from "../../utils/Alert.jsx";
import { timers } from "../../constants.jsx";

function NewPassword() {
  const navigate = useNavigate();
  const { handleSubmit, formState: { errors }, watch, control, setFocus } = useForm();
  const [onChangePasswordSuccessCalled, setOnChangePasswordSuccessCalled] = useState(false);
  const password = watch("password");
  const unknownError = "An unknown error occurred";

  const navigateToDashboard = (userType) => {
    switch (userType) {
      case "admin":
        navigate("/admin/programs/create");
        break;
      case "financier":
        navigate("/admin/programs/create");
        break;
      case "buyer":
        navigate("/admin/programs/create");
        break;
      default: // supplier
        navigate("/admin/programs/create");
    }
  };

  const passwordHeader = <h6>Pick a password</h6>;
  const passwordFooter = (
    <>
      <Divider />
      {errors.password && (
        <p className="p-error">{errors.password.message}</p>
      )}
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>Password must be at least 8 characters.</li>
        <li>Password must include a special character or a capital letter.</li>
        <li>Password must include a digit.</li>
      </ul>
    </>
  );

  const handleChangePasswordSuccess = (userType, doubleCallFunc) => {
    if (onChangePasswordSuccessCalled) { return; };

    doubleCallFunc(true);
    navigateToDashboard(userType);
  };

  const onSubmit = (data) => {
    const formattedData = new URLSearchParams(data).toString();
    var params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    axios
      .patch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/password/change?token=${token}`, formattedData)
      .then((response) => {
        const changeResponse = response.data;
        if (isNotEmpty(changeResponse)) {
          const userType = changeResponse.user_type;
          ShowAlert("success", changeResponse.message, () => {
            handleChangePasswordSuccess(userType, setOnChangePasswordSuccessCalled);
          });
          setTimeout(() => {
            handleChangePasswordSuccess(userType, setOnChangePasswordSuccessCalled);
          }, timers.LONG_DELAY);
        } else {
          ShowAlert("error", changeResponse?.error || unknownError);
        }
      })
      .catch((error) => {
        var errorResponse = error.response?.data;
        ShowAlert("error", errorResponse?.error || unknownError);
      });
  };

  const checkPasswordStrength = (value) => {
    const digitRegex = new RegExp("[0-9]");
    const specialCharacterRegex = /[~`!@#$%^&*()\-_=+{}[\]\\|;:"<>,./?A-Z]/;
    if (value.length < 8) {
      return "Password must be at least 8 characters.";
    }
    if (!specialCharacterRegex.test(value)) {
      return "Password must include a special character or a capital letter.";
    }
    if (!digitRegex.test(value)) {
      return "Password must include a digit.";
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="container-sm">
          <div className="text-center">
            <img src="/images/swiftly-full-logo.png" alt="Logo" width={520} />
            <h2 className="mt-4">Set a new password</h2>
            <p className="lead mt-2">Enter your new password</p>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row row mb-4">
                <div className="form-group col-md-12 mt-4">
                  <label htmlFor="password" className="form-label">
                    New Password
                  </label>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: "Password is required",
                      validate: { required: checkPasswordStrength },
                    }}
                    render={({ field, fieldState }) => (
                      <Password
                        type="password"
                        id={field.name}
                        {...field}
                        toggleMask
                        placeholder="Enter password"
                        header={passwordHeader}
                        footer={passwordFooter}
                        className={`w-full ${fieldState.invalid ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.password && (
                    <small className="p-error">{errors.password.message}</small>
                  )}
                </div>
                <div className="form-group col-md-12 mt-4">
                  <label htmlFor="password" className="form-label">
                    Confirm Password
                  </label>
                  <Controller
                    name="confirm_password"
                    control={control}
                    rules={{
                      required: "Password confirmation is required",
                      validate: {
                        required: (value) => {
                          checkPasswordStrength(value);
                          if (value !== password) {
                            setFocus("confirm_password");
                            return "Passwords do not match";
                          }
                        },
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <Password
                        type="password"
                        id={field.name}
                        {...field}
                        toggleMask
                        feedback={false}
                        placeholder="Confirm your password"
                        className={`w-full ${fieldState.invalid ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.confirm_password && (
                    <small className="p-error">
                      {errors.confirm_password.message}
                    </small>
                  )}
                  <div className="text-end">
                    <span>
                      <Link to={"/login"} onClick={() => {}}>
                        back to login
                      </Link>
                    </span>
                  </div>
                </div>
                <div className="form-group col-md-12 mt-4">
                  <Button
                    className="w-full"
                    type="submit"
                    label="Change password"
                    rounded
                    severity="primary"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewPassword;
