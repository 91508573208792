const getUserDashboard = (userType) => {
  switch (userType) {
    case "Administrator":
      return "/admin/programs";
    case "Financier":
      return "/financier";
    case "Buyer":
      return "/buyer";
    default: // supplier
      return "/supplier";
  }
};

export default getUserDashboard;
