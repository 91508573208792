import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AdminSupplierCrudActionsBodyTemplate(pk){
  const navigate = useNavigate();
  return (      
    <>
      <Button 
        icon={(options) => <FontAwesomeIcon icon={["fas", "eye"]}  {...options.iconProps} /> }
        label="View details"
        outlined
        rounded
        severity="grey"
        onClick={() => navigate(`/admin/suppliers/${pk}`)}
      />
      <Button 
        icon={(options) => <FontAwesomeIcon icon={["fas", "pen-to-square"]}  {...options.iconProps} /> }
        label="Edit"
        outlined
        rounded
        severity="grey" 
        onClick={() => navigate(`/admin/suppliers/${pk}/edit`)}
      />
    </>
  );
};

export default AdminSupplierCrudActionsBodyTemplate;
