import { useState,  useEffect, useCallback } from "react";
import apiAxios from "../../../axios.js";
import { useParams } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Header from "../../includes/Header.jsx";
import ShowAlert from "../../utils/Alert.jsx";

import { isEmpty, isNotEmpty } from "../../helpers/isEmptyHelpers.jsx";
import { camelizeWithSpaces } from "../../helpers/camelizeWithSpaces.jsx";
import InvoiceActionAlert from "../../utils/InvoiceActionAlert.jsx";
import formatCurrency from "../../helpers/formatCurrency.jsx";
import formatDate from "../../helpers/formatDate.jsx";
import formatPercentage from "../../helpers/formatPercentage.jsx";

library.add(fas);

function FinancierShowProgramInvoice() {

  const { invoicePk } = useParams();
  const [invoice, setInvoice] = useState({});
  const unknownError = "An unknown error occurred";
  const noInvoiceError = "No invoice found";

  const fetchInvoice = useCallback(() => {
    apiAxios
      .get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/financier/program/invoice?invoice_pk=${invoicePk}`)
      .then((response) => {
        const editResponse = response.data.invoice;
        if (isEmpty(editResponse)) {
          ShowAlert("warning", response?.error ?? noInvoiceError);
        } else {
          setInvoice(editResponse);
        }
      })
      .catch((error) => {
        ShowAlert("error", error.response?.data?.error || unknownError);
      });
  }, [invoicePk]);

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  const leftToolbarHeaderTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <h1>Request details</h1>
      </div>
    );
  };

  const rightToolbarHeaderTemplate = () => {
    return (
      <>
        {invoice.humanized_status === "request_is_sent" && (
          <>
            <Button 
              icon={(options) => <FontAwesomeIcon icon={["fas", "square-check"]}  {...options.iconProps} /> }
              label="Approve"
              outlined
              rounded
              severity="success"
              onClick={() => InvoiceActionAlert({
                pk: invoicePk,
                amount: invoice?.amount,
                buyer: invoice.program?.buyer?.display_name,
                anticipatedValue: invoice?.anticipated_amount,
                dueDate: invoice?.due_at,
              },
              "confirm",
              fetchInvoice)} 
            />
            <Button 
              icon={(options) => <FontAwesomeIcon icon={["fas", "xmark"]}  {...options.iconProps} /> }
              label="Deny"
              outlined
              rounded
              severity="danger" 
              onClick={() => InvoiceActionAlert({
                pk: invoicePk,
                amount: invoice?.amount,
                buyer: invoice.program?.buyer?.display_name,
                anticipatedValue: invoice?.anticipated_amount,
                dueDate: invoice?.due_at,
              },
              "deny",
              fetchInvoice)} 
            />
          </>
        )}
        <Button 
          className="has-icon"
          label="Print"
          icon={(options) => <FontAwesomeIcon icon={["fas", "print"]}  {...options.iconProps} /> } 
          rounded
          outlined
          severity="grey"
          onClick={() => window.print()}
        />      
      </>
    );
  };

  const InvoiceFeeSection = (invoice) => {
    if (invoice.early_payment_requested) {
      let fees;
      let header;
      if (["approved", "request_is_sent"].includes(invoice.humanized_status)) {
        fees = invoice.simulated_fees;
        header = "Simulated fees";
      } else if (["paid"].includes(invoice.humanized_status)) {
        fees = invoice.early_payment_request_fees;
        header = "Current fees";
      }
      return (
        <>
          <h5 className="underline mt-4">{ header }</h5>
          <div className="info-text row">
            {fees &&
              fees.map((fee) => (
                <div className="col-6 mt-3" key={`${fee.name}`}>
                  <h6>{camelizeWithSpaces(fee.name)}</h6>
                  <p>{formatCurrency(fee.amount, invoice.currency)}</p>
                </div>
              ))}
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="container-md my-4">
          <Toolbar className="px-0" start={leftToolbarHeaderTemplate} end={rightToolbarHeaderTemplate}></Toolbar>
          <div className="card">
            <div className="card-body mt-3">
              <h5 className="underline">General info</h5>
              <div className="info-text row">
                <div className="col-12 mt-3">
                  <h6>Program name</h6>
                  <p>{ invoice.program?.name }</p>
                </div>
                <div className="col-12 mt-3">
                  <h6>Supplier</h6>
                  <p>{ invoice.supplier?.legal_name }({ invoice.supplier?.tax_id})</p>
                </div>
                { invoice?.program?.buyer && (
                  <div className="col-12 mt-3">
                    <h6>Buyer</h6>
                    <p>{ invoice.program.buyer.display_name }</p>
                  </div>
                ) }
                <div className="col-4 mt-3">
                  <h6>Status</h6>
                  <p>{ invoice?.humanized_status ? camelizeWithSpaces(invoice.humanized_status) : "Status not available" }</p>
                </div>
                <div className="col-4 mt-3">
                  <h6>Requested at</h6>
                  <p>{ isNotEmpty(invoice?.created_at) && formatDate(invoice.created_at) }</p>
                </div>
                <div className="col-4 mt-3">
                  <h6>Approved at</h6>
                  <p>{ isNotEmpty(invoice?.approved_at) && formatDate(invoice.approved_at) }</p>
                </div>
                <div className="col-4 mt-3">
                  <h6>Credit requested total</h6>
                  <p>{ isNotEmpty(invoice?.amount) && formatCurrency(invoice.amount, invoice.currency) }</p>
                </div>
                <div className="col-4 mt-3">
                  <h6>Requested percentage</h6>
                  <p>{ isNotEmpty(invoice?.percentage_requested) && formatPercentage(invoice.percentage_requested) }</p>
                </div>
                <div className="col-4 mt-3">
                  <h6>Discounted value</h6>
                  <p>{ isNotEmpty(invoice?.discount_amount) && formatCurrency(invoice.discount_amount, invoice.currency) }</p>
                </div>
                <div className="col-4 mt-3">
                  <h6>Anticipated value</h6>
                  <p>{ isNotEmpty(invoice?.anticipated_amount) && formatCurrency(invoice.anticipated_amount, invoice.currency) }</p>
                </div>
                <div className="col-4 mt-3">
                  <h6>Due Date</h6>
                  <p>{ isNotEmpty(invoice?.due_at) && formatDate(invoice.due_at) }</p>
                </div>
              </div>
              { InvoiceFeeSection(invoice) }

              <h5 className="underline mt-4">Bank account details</h5>
              <div className="info-text row">
                <div className="col-12 mt-3">
                  <h6>Corporate name</h6>
                  <p>{ invoice?.bank_account_detail?.account_name }</p>
                </div>
                <div className="col-12 mt-3">
                  <h6>Bank name</h6>
                  <p>{ invoice?.bank_account_detail?.bank_name ? camelizeWithSpaces(invoice.bank_account_detail.bank_name) : "Bank name not available" }</p>
                </div>
                <div className="col-4 mt-3">
                  <h6>Branch</h6>
                  <p>{ invoice?.bank_account_detail?.bank_branch }</p>
                </div>
                <div className="col-4 mt-3">
                  <h6>Account</h6>
                  <p>{ invoice?.bank_account_detail?.account_number }</p>
                </div>
                <div className="col-4 mt-3">
                  <h6>Account Type</h6>
                  <p>{ invoice?.bank_account_detail?.account_type && camelizeWithSpaces(invoice.bank_account_detail.account_type) }</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default FinancierShowProgramInvoice;
