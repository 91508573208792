import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputOtp } from "primereact/inputotp";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import { isEmpty } from "../../helpers/isEmptyHelpers.jsx";
import ShowAlert from "../../utils/Alert.jsx";
import { timers } from "../../constants.jsx";

function LoginVerification() {
  const NEW_CODE_TIMER_LIMIT = 120; // 2 minutes
  const SUBSEQUENT_CODES_TIMER_LIMIT = 300; // 5 minutes
  const ONE_SECOND_IN_MILLISECONDS = 1000;
  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } } = useForm();
  const unknownError = "An unknown error occurred";
  const [onLoginSuccessCalled, setOnLoginSuccessCalled] = useState(false);
  const [sendNewCodeTimer, setSendNewCodeTimer] = useState(NEW_CODE_TIMER_LIMIT);

  useEffect(() => {
    if (sendNewCodeTimer > 0) {
      setTimeout(() => setSendNewCodeTimer(sendNewCodeTimer - 1), ONE_SECOND_IN_MILLISECONDS);
    }
  }, [sendNewCodeTimer]);

  const handleResendCodeClicked = (e) => {
    e.preventDefault();
    var params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/resend_login_otp?token=${token}`)
      .then((response) => {
        const resendResponse = response.data;
        if (!isEmpty(resendResponse)) {
          ShowAlert("success", resendResponse.message);
          setSendNewCodeTimer(SUBSEQUENT_CODES_TIMER_LIMIT);
        } else {
          ShowAlert("error", resendResponse?.error);
        }
      })
      .catch((error) => {
        ShowAlert("error", error?.response?.data?.error || unknownError);
      });
  };

  const sendNewCodeUrl = () => {
    if (sendNewCodeTimer > 0) {
      return (
        <div className="form-group col-md-12 mt-4 d-flex justify-content-between">
          <span>
            Send a new code in{" "}
            <span className="ex-text-primary">{sendNewCodeTimer}</span> seconds
          </span>
        </div>
      );
    } else {
      return (
        <div className="form-group col-md-12 mt-4 d-flex justify-content-between">
          <Link to={"#"} onClick={handleResendCodeClicked}>
            Send new code
          </Link>
        </div>
      );
    }
  };

  // The doubleCallFunc parameter is used to prevent this function from being called twice
  const handleVerificationSuccess = (verifyResponse, doubleCallFunc) => {
    if (onLoginSuccessCalled) { return; };

    doubleCallFunc(true);
    switch (verifyResponse.user_type) {
      case "admin":
        navigate("/admin/programs/create");
        break;
      case "financier":
        navigate("/admin/programs/create");
        break;
      case "buyer":
        navigate("/admin/programs/create");
        break;
      default: // supplier
        navigate("/admin/programs/create");
    }
  };

  const onSubmit = (data) => {
    var params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    axios.post(`/api/v1/verify_otp?token=${token}&otp=${data.code}`)
      .then((response) => {
        const verifyResponse = response.data;
        if (!isEmpty(verifyResponse)) {
          ShowAlert("success", verifyResponse?.message, () => { handleVerificationSuccess(verifyResponse, setOnLoginSuccessCalled); });
          setTimeout(() => {
            handleVerificationSuccess(verifyResponse, setOnLoginSuccessCalled);
          }, timers.LONG_DELAY);
        } else {
          ShowAlert("error", verifyResponse?.error);
        }
      })
      .catch((error) => {
        ShowAlert("error", error?.response?.data?.error || unknownError);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="container-sm text-center">
          <img
            src="/images/swiftly-logo.png"
            alt="Logo"
            width={100}
          />
          <h2 className="mt-3">Verify your identity</h2>
          <p className="lead mt-2">Please enter the verification code sent to your registered account.</p>
          <div className="mt-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row row mb-4">
                <div className="form-group col-md-12 mt-2 text-center">
                  {/* TODO: Fix the hover before launching */}
                  <Controller
                    control={control}
                    name="code"
                    rules={{
                      required: "Please enter an OTP",
                      minLength: {
                        value: 6,
                        message: "OTP must be 6 digits"
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <InputOtp 
                        length={6}
                        {...field}
                        value={field.value}
                        className={`w-full ${fieldState.invalid ? "p-invalid" : ""}`}
                        onChange={(e) => field.onChange(e.value)}
                        integerOnly
                      />
                    )}
                  />
                  {errors.code && (
                    <small className="p-error">{errors.code.message}</small>
                  )}
                </div>
                {sendNewCodeUrl()}
                <div className="form-group col-md-12 mt-4">
                  <Button 
                    className="w-full"
                    type="submit"
                    label="Verify"
                    rounded
                    severity="primary"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginVerification;
