import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InvoiceActionAlert from "../../utils/InvoiceActionAlert.jsx";

function FinancierRequestActionsBodyTemplate(programPk, invoice, fetchInvoices){
  const navigate = useNavigate();

  return (      
    <>
      <Button 
        icon={(options) => <FontAwesomeIcon icon={["fas", "eye"]}  {...options.iconProps} /> }
        label="View"
        outlined
        rounded
        severity="grey"
        onClick={() => navigate(`/financier/programs/${programPk}/invoices/${invoice.pk}`)}
      />
      { invoice.humanized_status === "request_is_sent" && (
        <>
          <Button 
            icon={(options) => <FontAwesomeIcon icon={["fas", "square-check"]}  {...options.iconProps} /> }
            label="Approve"
            outlined
            rounded
            severity="success"
            onClick={() => InvoiceActionAlert(
              invoice,
              "confirm",
              fetchInvoices
            )} 
          />
          <Button 
            icon={(options) => <FontAwesomeIcon icon={["fas", "xmark"]}  {...options.iconProps} /> }
            label="Deny"
            outlined
            rounded
            severity="danger"
            onClick={() => InvoiceActionAlert(
              invoice,
              "deny",
              fetchInvoices
            )} 
          />
        </>
      )}
    </>
  );
};

export default FinancierRequestActionsBodyTemplate;
