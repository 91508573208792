export const validationPattern = {
  percentage: {
    value: /^(100(\.0{1,2})?|(\d{1,2})(\.\d{1,2})?)$/,
    message: "Enter a valid percentage (0–100 and not more than 2 decimal places)"
  },
  numbers: {
    value: /^\d*\.?\d*$/,
    message: "Only numbers are allowed"
  },
  decimal: {
    value: /^\d+(\.\d{1,2})?$/,
    message: "Enter a valid number (not more than 2 decimal places)"
  },
  email: {
    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    message: "Enter a valid email address"
  },
};

export const validateNumeric = (e, setValue, setError, clearErrors, fieldName) => {
  const value = e.target.value;
  
  if (!/^\d*\.?\d*$/.test(value)) {
    setValue(fieldName, value.replace(/[^\d.]/g, ""));
    
    setError(fieldName, {
      type: "manual",
      message: "Only numbers are allowed"
    });
  } else {
    clearErrors(fieldName);
    const parsedIntValue = parseInt(value);
    setValue(fieldName, isNaN(parsedIntValue) ? "" : parsedIntValue);
  }
};

export const validateInteger = (e, setValue, fieldName) => {
  const value = parseInt(e.target.value);
  setValue(fieldName, isNaN(value) ? "" : value);
};
