import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FinancierViewActionsBodyTemplate(programPk, invoicePk){
  const navigate = useNavigate();
  return (    
    <Button 
      icon={(options) => <FontAwesomeIcon icon={["fas", "eye"]}  {...options.iconProps} /> }
      label="View"
      outlined
      rounded
      severity="grey"
      onClick={() => navigate(`/financier/programs/${programPk}/invoices/${invoicePk}`)}
    />
  );
};

export default FinancierViewActionsBodyTemplate;
