import { isEmpty } from "./isEmptyHelpers.jsx";

const camelizeWithSpaces = (stringToFormat) => {
  if (isEmpty(stringToFormat)) {
    return "";
  }

  return stringToFormat
    .toLowerCase()
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
    .join(" ");
  
};

export { camelizeWithSpaces };
