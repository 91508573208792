import SupplierStatCard from "../../cards/SupplierStatCard.jsx";
import PropTypes from "prop-types";

StatsRowTemplate.propTypes = {
  dashboardStatDataWrapper: PropTypes.any,
};

function StatsRowTemplate({ dashboardStatDataWrapper }) {
  const response = dashboardStatDataWrapper.read();
  let dashboardStats = [];
  dashboardStats = response.data.metrics;
  const currency = response.data.program.currency;
  return (
    <div className="row mx-2 mb-4">
      {
        dashboardStats.map((item) => (
          <SupplierStatCard
            key={item.name}
            icon={item.type}
            title={item.name}
            value={item.value}
            currency={currency}
          />
        ))
      }
    </div>
  );
};

export default StatsRowTemplate;
