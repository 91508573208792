import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./App.css";

// Application Routes
import Login from "./components/pages/auth/Login.jsx";
import Verification from "./components/pages/auth/Verification.jsx";
import ResetPassword from "./components/pages/auth/ResetPassword.jsx";
import NewPassword from "./components/pages/auth/NewPassword.jsx";
import AdminCreateSupplier from "./components/pages/admin/supplier/CreateSupplier.jsx";
import AdminEditSupplier from "./components/pages/admin/supplier/EditSupplier.jsx";
import AdminShowSupplier from "./components/pages/admin/supplier/ShowSupplier.jsx";
import AdminListSupplier from "./components/pages/admin/supplier/ListSupplier.jsx";
import AdminListProgram from "./components/pages/admin/program/ListProgram.jsx";
import AdminCreateProgram from "./components/pages/admin/program/CreateProgram.jsx";
import AdminEditProgram from "./components/pages/admin/program/EditProgram.jsx";
import AdminShowProgram from "./components/pages/admin/program/ShowProgram.jsx";
import FinancierListProgramInvoice from "./components/pages/financier/FinancierListProgramInvoice.jsx";
import FinancierShowProgramInvoice from "./components/pages/financier/FinancierShowProgramInvoice.jsx";
import SupplierDashboard from "./components/pages/supplier/SupplierDashboard.jsx";
import BuyerDashboard from "./components/pages/buyer/BuyerDashboard.jsx";
import FinancierDashboard from "./components/pages/financier/FinancierDashboard.jsx";
import SupplierInvoices from "./components/pages/supplier/SupplierInvoices.jsx";
import SupplierInvoiceDetail from "./components/pages/supplier/SupplierInvoiceDetail.jsx";
import errorPages from "./components/common/errorRoutes.js";
import BuyerSupplierInvoices from "./components/pages/buyer/BuyerSupplierInvoices.jsx";
import BuyerSupplierInvoiceDetail from "./components/pages/buyer/BuyerSupplierInvoiceDetail.jsx";

import { isNotEmpty } from "./components/helpers/isEmptyHelpers.jsx";
import userStore from "./stores/UserStore.js";
import getUserDashboard from "./components/helpers/getUserDashboard.jsx";

export default function App() {
  const user = userStore((state) => state.user);
  const isAuthenticated = isNotEmpty(user.userType);

  const ProtectedRoute = ({ component }) => {
    if (isAuthenticated) {
      const dashboardPath = getUserDashboard(user.userType);
      return <Navigate to={dashboardPath} />;
    } else {
      return component;
    }
  };

  ProtectedRoute.propTypes = {
    component: PropTypes.element.isRequired,
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          <Route path="/login" element={<ProtectedRoute component={<Login/>} />} />
          <Route path="/activate-account" element={<ProtectedRoute component={<ResetPassword/>} />} />
          <Route path="/verification" element={<ProtectedRoute component={<Verification/>} />} />
          <Route path="/reset-password" element={<ProtectedRoute component={<ResetPassword/>} />} />
          <Route path="/change-password" element={<ProtectedRoute component={<NewPassword/>} />} />
          <Route path="/admin/suppliers/create" element={<AdminCreateSupplier />} />
          <Route path="/admin/suppliers/:pk/edit" element={<AdminEditSupplier />} />
          <Route path="/admin/suppliers/:pk" element={<AdminShowSupplier />} />
          <Route path="/admin/suppliers" element={<AdminListSupplier />} />
          <Route path="/admin/programs/create" element={<AdminCreateProgram />} />
          <Route path="/admin/programs/:pk/edit" element={<AdminEditProgram />} />
          <Route path="/admin/programs/:pk" element={<AdminShowProgram />} />
          <Route path="/admin/programs" element={<AdminListProgram />} />
          <Route path="/financier/programs/:pk/invoices" element={<FinancierListProgramInvoice />} />
          <Route path="/financier/programs/:programPk/invoices/:invoicePk" element={<FinancierShowProgramInvoice />} />
          <Route path="/supplier" element={<SupplierDashboard />} />
          <Route path="/supplier/invoice/show_invoice_detail/:pk" element={<SupplierInvoiceDetail />} />
          <Route path="/supplier/show_invoices/:pk" element={<SupplierInvoices />} />
          <Route path="/buyer" element={<BuyerDashboard />} />
          <Route path="/buyer/supplier/show_invoices/:pk" element={<BuyerSupplierInvoices />} />
          <Route path="/buyer/supplier/invoices/show_invoice_detail/:pk" element={<BuyerSupplierInvoiceDetail />} />
          <Route path="/financier" element={<FinancierDashboard />} />
          {errorPages}
        </Route>
      </Routes>
    </Router>
  );
}

function Layout() {
  return (
    <div className="App">
      <Outlet />
    </div>
  );
}
