import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayPanel } from "primereact/overlaypanel";

AmountFilter.propTypes = {
  onAmountChange: PropTypes.func.isRequired,
  setIsClearFilterVisible: PropTypes.func.isRequired,
};

export default function AmountFilter({ onAmountChange, setIsClearFilterVisible }) {
  const recordFilterReference = useRef(null);

  const RecordFilterForm = () => {
    const [amountRange, setAmountRange] = useState([50,5000]);

    const handleAmountChange = (e, index) => {
      const value = e.target.value === "" ? "" : parseInt(e.target.value);
      const newAmountRange = [...amountRange];
      newAmountRange[index] = isNaN(value) ? "" : value;
      setAmountRange(newAmountRange);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      onAmountChange(amountRange[0], amountRange[1]);
      setIsClearFilterVisible(true);
      recordFilterReference.current.hide();
    };

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="form record-filter-form p-3">
            <div className="form-row row pb-4">
              <div className="form-group col-12">
                <label htmlFor="status" className="form-label">Charge amount in BWP</label>
                <Slider 
                  value={amountRange} 
                  onChange={(e) => setAmountRange(e.value)}
                  range
                  step={100}
                  min={1}
                  max={100000}
                />
              </div>
              <div className="form-group col-6">
                <small className="text-black">From</small>
                <InputText 
                  id="from" 
                  value={amountRange[0]} 
                  onChange={(e) => handleAmountChange(e, 0)} 
                />
              </div>
              <div className="form-group col-6 d-block">
                <small className="text-black">To</small>
                <InputText 
                  id="to" 
                  value={amountRange[1]} 
                  onChange={(e) => handleAmountChange(e, 1)} 
                />
              </div>
            </div>
            <div className="form-row row px-2">
              <div className="form-group col-6 px-1">
                <Button 
                  className="w-full"
                  label="Cancel" 
                  size="small"
                  rounded
                  outlined
                  severity="grey"
                  onClick={(e) => {
                    e.preventDefault();
                    recordFilterReference.current.hide();
                  }}
                />   
              </div>
              <div className="form-group col-6 px-1">
                <Button
                  className="w-full" 
                  label="Show Results" 
                  size="small"
                  rounded
                  type="submit"
                />   
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };
  
  return (
    <>
      <Button
        className="has-icon"
        label="Filter"
        icon={(options) => <FontAwesomeIcon icon={["fas", "filter"]}  {...options.iconProps} />}
        rounded
        outlined
        severity="grey"
        onClick={(e) => recordFilterReference.current.toggle(e)} 
      />
      <OverlayPanel ref={recordFilterReference}>
        <RecordFilterForm />
      </OverlayPanel>
    </>
  );
}
