import axios from "axios";
import { timers } from "./components/constants.jsx";
import ShowAlert from "./components/utils/Alert.jsx";
import { isNotEmpty } from "./components/helpers/isEmptyHelpers.jsx";

const apiAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL, // Rails API URL
  withCredentials: true, // Ensure cookies are sent
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

var userIsRedirected = false;
const clearLocalStorage = () => {
  localStorage.clear();
};

const navigateToVerification = (token) => {
  clearLocalStorage();
  if (userIsRedirected) { return; };
  userIsRedirected = true;
  window.location.href = `/verification?token=${token}`;
};

const navigateToLogin = () => {
  clearLocalStorage();
  if (userIsRedirected) { return; };
  userIsRedirected = true;
  window.location.href = "/login";
};

apiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    var errorResponse = error.response?.data;
    if (
      isNotEmpty(errorResponse?.error) &&
      errorResponse.error.toLowerCase().includes("complete 2fa")
    ) {
      ShowAlert("warning", errorResponse.error, () => {
        navigateToVerification(errorResponse.token);
      });
      setTimeout(() => {
        navigateToVerification(errorResponse.token);
      }, timers.SHORT_DELAY);
    } else if (
      isNotEmpty(errorResponse?.error) &&
      errorResponse.error.toLowerCase().includes("please log in")
    ) {
      ShowAlert("error", errorResponse.error, () => {
        navigateToLogin(errorResponse.token);
      });
      setTimeout(() => {
        navigateToLogin(errorResponse.token);
      }, timers.SHORT_DELAY);
    } else if (
      isNotEmpty(errorResponse?.error) &&
      errorResponse.error.toLowerCase().includes("error sending otp")
    ) {
      ShowAlert("error", errorResponse.error, () => {
        navigateToLogin(errorResponse.token);
      });
      setTimeout(() => {
        navigateToLogin(errorResponse.token);
      }, timers.SHORT_DELAY);
    } else if (
      isNotEmpty(errorResponse?.error) &&
      errorResponse.error.toLowerCase().includes("only admins can access")
    ) {
      ShowAlert("error", errorResponse.error, () => {
        navigateToLogin(errorResponse.token);
      });
      setTimeout(() => {
        navigateToLogin(errorResponse.token);
      }, timers.SHORT_DELAY);
    }
    return Promise.reject(error);
  }
);

export default apiAxios;
