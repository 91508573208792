import apiAxios from "../../../../axios.js";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { timers } from "../../../constants.jsx";
import { isNotEmpty } from "../../../helpers/isEmptyHelpers.jsx";
import ShowAlert from "../../../utils/Alert.jsx";
import Header from "../../../includes/Header.jsx";
import LeftMenu from "../../../includes/LeftMenu.jsx";
import SupplierForm from "../../../forms/SupplierForm.jsx";

library.add(fas);

function CreateSupplier() {
  const navigate = useNavigate();
  const unknownError = "An unknown error occurred";
  const { register, handleSubmit, setValue, control, watch, formState: { errors } } = useForm({
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const onSubmit = (data) => {
    apiAxios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/admin/suppliers`, data)
      .then((response) => {
        var createResponse = response.data;
        if (isNotEmpty(createResponse)) {
          ShowAlert("success", "Supplier created successfully");
          setTimeout(() => {
            navigate("/admin/suppliers");
          }, timers.SHORT_DELAY);
        } else {
          ShowAlert("warning", response?.error || unknownError);
        }
      })
      .catch((error) => {
        ShowAlert("error", error.response?.data?.error || unknownError);
      });
  };

  const centerToolbarHeaderTemplate = () => {
    return (
      <h1>Add New Supplier</h1>
    );
  };

  const rightToolbarFooterTemplate = () => {
    return (
      <Button
        icon={(options) => <FontAwesomeIcon icon={["fas", "check"]}  {...options.iconProps} /> }
        type="submit"
        label="Add supplier"
        rounded
        severity="primary"
      />
    );
  };

  return (
    <>
      <Header />
      <main>
        <div className="container row p-4">
          <LeftMenu />
          <div className="col-10">
            <Toolbar center={centerToolbarHeaderTemplate}></Toolbar>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card">
                <div className="card-body">
                  <SupplierForm
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    control={control}
                    watch={watch}
                  />
                </div>
              </div>
              <Toolbar className="px-0" end={rightToolbarFooterTemplate}></Toolbar>
            </form>
          </div>
        </div>
      </main>
    </>
  );
}

export default CreateSupplier;
