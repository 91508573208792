import { isEmpty } from "./isEmptyHelpers.jsx";

function formatCurrency(fiatNumberToFormat, currency = "BWP") {
  if (isEmpty(fiatNumberToFormat)) {
    return "";
  }

  const numberToFormat = parseFloat(fiatNumberToFormat) / 100;
  return numberToFormat.toLocaleString("en-GB", {
    style: "currency",
    currency: currency,
    currencyDisplay: "narrowSymbol",
    currencySign: "accounting",
  });
}

export default formatCurrency;
