import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

async function ShowStickyAlert(severity, message, options = {}) {
  let icon = "";
  let iconColour = "";

  switch (severity) {
    case "info":
      icon = "info";
      iconColour = "blue";
      break;
    case "success":
      icon = "success";
      iconColour = "green";
      break;
    case "warning":
      icon = "warning";
      iconColour = "orange";
      break;
    case "error":
      icon = "error";
      iconColour = "red";
      break;
    default:
      icon = "info";
      iconColour = "blue";
      break;
  }

  const {
    onConfirm = () => {},
    showConfirmButton = true,
    confirmButtonText = "OK",
    showCancelButton = false,
    cancelButtonText = "Cancel",
    confirmButton = "swal2-confirm-ok",
    cancelButton = "swal2-cancel",
  } = options;

  const result = await withReactContent(Swal).fire({
    title: severity.charAt(0).toUpperCase() + severity.slice(1),
    icon: icon,
    iconColor: iconColour,
    buttonsStyling: false,
    backdrop: "rgba(0,0,0,0.4)",
    customClass: {
      confirmButton: confirmButton,
      cancelButton: cancelButton,
    },
    text: message,
    showConfirmButton,
    confirmButtonText,
    showCancelButton,
    cancelButtonText,
  });
  if (result.isConfirmed) {
    onConfirm();
  }

  return result;
}

export default ShowStickyAlert;
