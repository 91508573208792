import apiAxios from "../../axios.js";
import { PropTypes } from "prop-types";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import userStore from "../../stores/UserStore.js";
import { isNotEmpty } from "../helpers/isEmptyHelpers.jsx";
import ShowAlert from "../utils/Alert.jsx";
import notificationStore from "../../stores/NotificationStore.js";

library.add(fas);

NotificationCard.propTypes = {
  notification: PropTypes.object,
};

function NotificationCard({ notification }) {
  const user = userStore((state) => state.user);
  const removeNotification = notificationStore((state) => state.removeNotification);
  const unknownError = "An unknown error occurred";

  const redirectWithRead = () => {
    apiAxios
      .get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/notification?pk=${notification.pk}`)
      .then((response) => {
        if (isNotEmpty(response?.data)) {
          const invoicePk = response?.data?.payload?.pk;
          removeNotification(notification.pk);
          switch (user.userType) {
            case "Financier":
              window.location.href = `${process.env.REACT_APP_BASE_APP_URL}/programs/current/invoices/${invoicePk}`;
              break;
            case "Supplier": {
              window.location.href = `${process.env.REACT_APP_BASE_APP_URL}/supplier/invoice/show_invoice_detail/${invoicePk}`;
              break;
            }
          }
        } else {
          ShowAlert("error", unknownError);
        }
      })
      .catch((error) => {
        var errorResponse = error.response?.data;
        ShowAlert("error", errorResponse?.error || unknownError);
      });
  };

  const connotationIcon = (connotation) => {
    switch (connotation) {
      case "positive":
        return <FontAwesomeIcon icon="check-circle" className="fas icon p-button-success" />;
      case "negative":
        return <FontAwesomeIcon icon="circle-xmark" className="fas icon p-button-danger" />;
      case "neutral":
        return <FontAwesomeIcon icon="info-circle" className="fas icon p-button-info" />;
      default:
        return <FontAwesomeIcon icon="bell" className="fas icon" />;
    }
  };

  return (
    <>
      <hr />
      <div className="row px-3">
        <div className="col-1">
          { connotationIcon(notification.connotation) }
        </div>
        <div className="col-11">
          <p>{ notification.message }</p>
          <Button 
            className="p-button-sm"
            icon={(options) => <FontAwesomeIcon icon={["fas", "chevron-right"]}  {...options.iconProps} /> }
            iconPos="right"
            label="View"
            outlined
            rounded
            severity="grey"
            onClick={redirectWithRead}
          />
        </div>
      </div>
    </>
  );
};

export default NotificationCard;
