import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import formatCurrency from "../helpers/formatCurrency.jsx";
import SupplierInvoiceFee from "../utils/SupplierInvoiceFee.jsx";
import SupplierInvoiceTopSection from "./SupplierInvoiceTopSection.jsx";
import { getSelectedFee } from "../utils/FeeUtils.jsx";
import SupplierInvoiceBottomSection from "./SupplierInvoiceBottomSection.jsx";

library.add(fas);

ApprovedInvoiceSection.propTypes = {
  invoice: PropTypes.object,
};

function ApprovedInvoiceSection({ invoice }) {
  const selectedFee = getSelectedFee(invoice);

  return (
    <div className="card card-invoice p-0">
      <div className="card-header">
        <img src="/images/swiftly-logo.png" alt="Logo" className="logo-overlay" />
        <p className="m-0">This request has been approved and will be paid within 24 hours. Thereafter, you can expect it to reflect on your bank account within 3 business days.</p>
        <p className="bold-xxl">{formatCurrency(selectedFee.expected_amount, invoice.currency)}</p>
      </div>
      <div className="card-body">
        <SupplierInvoiceTopSection invoice={invoice} />
        <hr />
        <SupplierInvoiceFee invoice={invoice} earlyPaymentFees={selectedFee?.simulated_fee_list} />
        <SupplierInvoiceBottomSection invoice={invoice} fee={selectedFee} />
      </div>
    </div>
  );
};

export default ApprovedInvoiceSection;
