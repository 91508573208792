import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { statuses, companyTypes, accountTypes, bankNames } from "../constants.jsx";
import { validationPattern } from "../helpers/validationHelpers.jsx";

library.add(fas);

SupplierForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
};

function SupplierForm({ register, errors, control }){

  return (
    <>
      <h5 className="underline mt-2">Company Details</h5>
      <div className="form-row row mb-4">
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="legalName" className="form-label">
            Legal name
          </label>
          <InputText
            id="legalName"
            name="supplier[legal_name]"
            placeholder="Enter company name"
            {...register("supplier.legal_name", {
              required: "Legal Name is required",
              minLength: {
                value: 3,
                message: "Legal Name must be at least 3 characters long"
              },
              maxLength: {
                value: 50,
                message: "Legal Name must be less than 50 characters long"
              }
            })}
            className={`w-full ${errors.supplier?.legal_name ? "p-invalid" : ""}`}
          />
          {errors.supplier?.legal_name && (
            <small className="p-error">{errors.supplier.legal_name.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="supplierType" className="form-label">
            Type
          </label>
          <Controller
            id="supplierType"
            name="supplier[supplier_type]"
            control={control}
            defaultValue={null}
            rules={{ required: "Supplier type is required" }}
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                value={field.value}
                options={companyTypes}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                optionLabel="name"
                optionValue="index"
                placeholder="Select Supplier Type"
                className={`w-full ${fieldState.error ? "p-invalid" : ""}`}
              />
            )}
          />
          {errors.supplier?.supplier_type && (
            <small className="p-error">{errors.supplier.supplier_type.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="registrationId" className="form-label">
            Registration ID
          </label>
          <InputText
            name="supplier[registration_id]"
            id="registrationId"
            {...register("supplier[registration_id]", { required: "Registration ID is required" })}
            className={`w-full ${errors.supplier?.registration_id ? "p-invalid" : ""}`}
            placeholder="Enter registration ID"
          />
          {errors.supplier?.registration_id && (
            <small className="p-error">{errors.supplier.registration_id.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="physicalAddress" className="form-label">
            Physical address
          </label>
          <InputText
            name="supplier[physical_address]"
            id="physicalAddress"
            {...register("supplier[physical_address]", { required: "Physical Address is required" })}
            className={`w-full ${errors.supplier?.physical_address ? "p-invalid" : ""}`}
            placeholder="Enter physical address"
          />
          {errors.supplier?.physical_address && (
            <small className="p-error">{errors.supplier.physical_address.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="taxId" className="form-label">
            Tax ID
          </label>
          <InputText
            name="supplier[tax_id]"
            id="taxId"
            {...register("supplier[tax_id]", { required: "Tax ID is required" })}
            className={`w-full ${errors.supplier?.tax_id ? "p-invalid" : ""}`}
            placeholder="Enter tax ID"
          />
          {errors.supplier?.tax_id && (
            <small className="p-error">{errors.supplier.tax_id.message}</small>
          )}
        </div>
      </div>
      <h5 className="underline mt-5">Bank Account Details</h5>
      <div className="form-row row mb-4">
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="accountName" className="form-label">
            Account name
          </label>
          <InputText
            name="bank_account_detail[account_name]"
            id="accountName"
            {...register("bank_account_detail[account_name]", { required: "Account Name is required" })}
            className={`w-full ${errors.bank_account_detail?.account_name ? "p-invalid" : ""}`}
            placeholder="Enter your bank corporate name"
          />
          {errors.bank_account_detail?.account_name && (
            <small className="p-error">{errors.bank_account_detail.account_name.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="accountNumber" className="form-label">
            Account Number
          </label>
          <InputText
            name="bank_account_detail[account_number]"
            id="accountNumber"
            {...register("bank_account_detail[account_number]", { required: "Account Number is required" })}
            className={`w-full ${errors.bank_account_detail?.account_number ? "p-invalid" : ""}`}
            placeholder="Enter your account number"
          />
          {errors.bank_account_detail?.account_number && (
            <small className="p-error">{errors.bank_account_detail.account_number.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="bankName" className="form-label">
            Bank name
          </label>
          <Controller
            name="bank_account_detail[bank_name]"
            id="bankName"
            control={control}
            defaultValue={null}
            rules={{ required: "Bank name is required" }}
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                value={field.value}
                options={bankNames}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                optionLabel="name"
                optionValue="index"
                placeholder="Select Bank Name"
                className={`w-full ${fieldState.error ? "p-invalid" : ""}`}
              />
            )}
          />
          {errors.bank_account_detail?.bank_name && (
            <small className="p-error">{errors.bank_account_detail.bank_name.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="bankBranch" className="form-label">
            Bank branch
          </label>
          <InputText
            name="bank_account_detail[bank_branch]"
            id="bankBranch"
            {...register("bank_account_detail[bank_branch]", { required: "Bank Branch is required" })}
            className={`w-full ${errors.bank_account_detail?.bank_branch ? "p-invalid" : ""}`}
            placeholder="Enter branch"
          />
          {errors.bank_account_detail?.bank_branch && (
            <small className="p-error">{errors.bank_account_detail.bank_branch.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="accountType" className="form-label">
            Account type
          </label>
          <Controller
            id="accountType"
            name="bank_account_detail[account_type]"
            control={control}
            defaultValue={null}
            rules={{ required: "Account type is required" }}
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                value={field.value}
                options={accountTypes}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                optionLabel="name"
                optionValue="index"
                placeholder="Select Account Type"
                className={`w-full ${fieldState.error ? "p-invalid" : ""}`}
              />
            )}
          />
          {errors.bank_account_detail?.account_type && (
            <small className="p-error">{errors.bank_account_detail.account_type.message}</small>
          )}
        </div>
      </div>
      <h5 className="underline mt-5">Supplier Bank Contacts</h5>
      <div className="form-row row mb-4">
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="fullName" className="form-label">
            Full name
          </label>
          <InputText
            name="supplier_bank_contact[full_name]"
            id="fullName"
            {...register("supplier_bank_contact[full_name]", { required: "Full Name is required" })}
            className={`w-full ${errors.supplier_bank_contact?.full_name ? "p-invalid" : ""}`}
            placeholder="Enter your full name"
          />
          {errors.supplier_bank_contact?.full_name && (
            <small className="p-error">{errors.supplier_bank_contact.full_name.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="address" className="form-label">
            Address
          </label>
          <InputText
            name="supplier_bank_contact[address]"
            id="address"
            {...register("supplier_bank_contact[address]", { required: "Address is required" })}
            className={`w-full ${errors.supplier_bank_contact?.address ? "p-invalid" : ""}`}
            placeholder="Enter your address"
          />
          {errors.supplier_bank_contact?.address && (
            <small className="p-error">{errors.supplier_bank_contact.address.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="email" className="form-label">
            Email 
          </label>
          <InputText
            name="supplier_bank_contact[email]"
            id="email"
            {...register("supplier_bank_contact[email]",
              {
                required: "Email is required",
                pattern: validationPattern.email,
              }
            )}
            className={`w-full ${errors.supplier_bank_contact?.email ? "p-invalid" : ""}`}
            placeholder="Enter your email"
          />
          {errors.supplier_bank_contact?.email && (
            <small className="p-error">{errors.supplier_bank_contact.email.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="phoneNumber" className="form-label">
            Phone number
          </label>
          <InputText
            name="supplier_bank_contact[phone_number]"
            id="phoneNumber"
            {...register("supplier_bank_contact[phone_number]", { required: "Phone number is required" })}
            className={`w-full ${errors.supplier_bank_contact?.phone_number ? "p-invalid" : ""}`}
            placeholder="Enter your bank corporate name"
          />
          {errors.supplier_bank_contact?.phone_number && (
            <small className="p-error">{errors.supplier_bank_contact.phone_number.message}</small>
          )}
        </div>
      </div>
      <h5 className="underline mt-5">Supplier Settings</h5>
      <div className="form-row row mb-4">
        <div className="form-group col-md-12 mt-4">
          <label htmlFor="automaticDenyErrorMessage" className="form-label">
            Automatic Deny Error Message
          </label>
          <InputTextarea
            id="automaticDenyErrormessage"
            name="supplier[automatic_deny_error_message]"
            {...register("supplier[automatic_deny_error_message]", { required: "Message is required" })}
            className={`form-control ${errors.supplier?.automatic_deny_error_message ? "p-invalid" : ""}`}
            placeholder="Write the message when supplier cannot anticipate"
            autoResize
            rows={5}
            cols={30}
          />
          {errors.supplier?.automatic_deny_error_message && (
            <small className="p-error">{errors.supplier.automatic_deny_error_message.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="supplierLimitAmount" className="form-label">
            Supplier limit amount
          </label>
          <InputText
            keyfilter="int"
            name="supplier[supplier_limit_amount]"
            id="supplierLimitAmount"
            {...register("supplier[supplier_limit_amount]", { required: "Limit amount is required" })}
            className={`w-full ${errors.supplier?.supplier_limit_amount ? "p-invalid" : ""}`}
            placeholder="Enter supplier limit amount"
          />
          {errors.supplier?.supplier_limit_amount && (
            <small className="p-error">{errors.supplier.supplier_limit_amount.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <Controller
            id="status"
            name="supplier[status]"
            control={control}
            defaultValue={null}
            rules={{ required: "Status is required" }}
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                value={field.value}
                options={statuses.general}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                optionLabel="name"
                optionValue="index"
                placeholder="Select Status"
                className={`w-full ${fieldState.error ? "p-invalid" : ""}`}
              />
            )}
          />
          {errors.supplier?.status && (
            <small className="p-error">{errors.supplier.status.message}</small>
          )}
        </div>
      </div>
    </>
  );
};

export default SupplierForm;
