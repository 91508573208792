import PropTypes from "prop-types";

import formatCurrency from "../helpers/formatCurrency.jsx";
import formatDate from "../helpers/formatDate.jsx";

SupplierInvoiceTopSection.propTypes = {
  invoice: PropTypes.object,
};

function SupplierInvoiceTopSection({ invoice }) {
  return (
    <>
      <div className="d-flex justify-content-between my-3">
        <h6>Original Value</h6>
        <p className="bold-sm p-0 underline">{formatCurrency(invoice.amount, invoice.currency)}</p>
      </div>
      <div className="d-flex justify-content-between my-3">
        <h6>Due Date</h6>
        <p className="bold-sm p-0 underline">{formatDate(invoice.due_at)}</p>
      </div>
    </>
  );
};

export default SupplierInvoiceTopSection;
