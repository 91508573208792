import PropTypes from "prop-types";
import { Button } from "primereact/button";
import AmountFilter from "../utils/AmountFilter.jsx";
import DateFilter from "../utils/DateFilter.jsx";
import SearchFilter from "../utils/SearchFilter.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

InvoiceFilterActions.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onAmountChange: PropTypes.func.isRequired,
  updateTableLazyState: PropTypes.func.isRequired,
  setIsClearFilterVisible: PropTypes.func.isRequired,
  isClearFilterVisible: PropTypes.bool.isRequired,
};

function InvoiceFilterActions({ onSearch, onDateChange, onAmountChange, updateTableLazyState, setIsClearFilterVisible, isClearFilterVisible }) {
  return (
    <>
      <div className="row">
        <SearchFilter onSearch={onSearch} />
        <div className="col-12 col-md-auto d-flex p-0">
          <DateFilter onDateChange={onDateChange} setIsClearFilterVisible={setIsClearFilterVisible} />
          <AmountFilter onAmountChange={onAmountChange} setIsClearFilterVisible={setIsClearFilterVisible} />
          {isClearFilterVisible && (
            <Button
              className="has-icon"
              label="Clear Filter"
              icon={(options) => <FontAwesomeIcon icon={["fas", "filter-circle-xmark"]}  {...options.iconProps} />}
              rounded
              severity="danger"
              onClick={() => {
                updateTableLazyState({
                  first: 0,
                  rows: 25,
                  page: 1,
                  sortField: null,
                  sortOrder: null,
                  filters: {}
                });
                setIsClearFilterVisible(false);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceFilterActions;
