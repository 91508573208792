import { isEmpty } from "./isEmptyHelpers.jsx";

function formatPercentage(numberFieldData) {
  if (isEmpty(numberFieldData)) {
    return ""; 
  };

  return `${ parseFloat(numberFieldData) }%`;
}

export default formatPercentage;
