import { useState, Suspense } from "react";
import PropTypes from "prop-types";
import apiAxios from "../../../axios.js";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Toolbar } from "primereact/toolbar";

import Header from "../../includes/Header.jsx";
import ListEntityItems from "../../utils/ListEntityItems.jsx";
import ShowAlert from "../../utils/Alert.jsx";
import LoadingFallback from "../../utils/Loading.jsx";

import { isEmpty } from "../../helpers/isEmptyHelpers.jsx";
import { apiWrapper } from "../../common/apiWrapper.js";

library.add(fas);

const BuyerDashboard = () => {
  const noSupplierError = "No suppliers found";

  const supplierResource = apiWrapper(() =>
    apiAxios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/buyers`)
  );

  const SuppliersList = ({ resource }) => {
    const [globalFilter, setGlobalFilter] = useState("");
    let suppliers = [];
    const response = resource.read();
    if (isEmpty(response?.data)) {
      ShowAlert("warning", response?.error ?? noSupplierError);
    } else {
      suppliers = response.data?.suppliers;
    }

    const filteredSuppliers = suppliers.filter(supplier => {
      if (isEmpty(globalFilter.trim())) { return true; }

      const searchTerm = globalFilter.toLowerCase().trim();
      return (
        supplier?.legal_name?.toLowerCase().includes(searchTerm) ||
        supplier?.tax_id?.toLowerCase().includes(searchTerm) ||
        supplier?.total_invoices_pending_amount?.toString().includes(searchTerm) ||
        supplier?.program_name?.toString().includes(searchTerm)
      );
    });

    const rightToolbarHeaderTemplate = () => {
      return (
        <>
          <IconField iconPosition="left">
            <FontAwesomeIcon className="p-input-icon" icon={["fas", "magnifying-glass"]} />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
          </IconField>
        </>
      );
    };

    const leftToolbarHeaderTemplate = () => {
      if (suppliers.length) {
        return (
          <div className="flex flex-wrap gap-2">
            <h1>Select a Supplier</h1>
          </div>
        );
      } else {
        return (
          <div className="flex flex-wrap gap-2">
            <h1>{noSupplierError}</h1>
          </div>
        );
      }
    };

    return (
      <>
        <Toolbar className="px-0" start={leftToolbarHeaderTemplate} end={rightToolbarHeaderTemplate} />
        <ListEntityItems entities={filteredSuppliers} entityType="supplier" />
      </>
    );
  };

  SuppliersList.propTypes = {
    resource: PropTypes.shape({
      read: PropTypes.func.isRequired
    }).isRequired,
  };

  return (
    <>
      <Header />
      <main>
        <div className="container-xl my-4">
          <Suspense fallback={<LoadingFallback />}>
            <SuppliersList resource={supplierResource} />
          </Suspense>
        </div>
      </main>
    </>
  );
};

export default BuyerDashboard;
