import { useCallback, useEffect, useRef, useState } from "react";
import apiAxios from "../../../../axios.js";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import LeftMenu from "../../../includes/LeftMenu.jsx";
import Header from "../../../includes/Header.jsx";
import ShowAlert from "../../../utils/Alert.jsx";
import AdminProgramCrudActionsBodyTemplate from "../../../tables/templates/AdminProgramCrudActionsBodyTemplate.jsx";
import formatStatus from "../../../helpers/formatStatus.jsx";
import formatPercentage from "../../../helpers/formatPercentage.jsx";
import SearchFilter from "../../../utils/SearchFilter.jsx";
import formatCurrency from "../../../helpers/formatCurrency.jsx";

import { apiWrapper } from "../../../common/apiWrapper.js";
import sortHelper from "../../../helpers/sortHelper.jsx";

library.add(fas);

function ListProgram() {
  const navigate = useNavigate();
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [programRecordCount, setProgramRecordCount] = useState(0);
  const dataTableReference = useRef(null);
  const noProgramsError = "No programs available";
  const [tableLazyState, setTableLazyState] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {}
  });

  const updateTableLazyState = (newState) => {
    setTableLazyState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const onPage = (event) => {
    updateTableLazyState({
      first: event.first,
      rows: event.rows,
      page: event.page + 1,
    });
  };

  const onSort = (event) => {
    const { sortField, sortOrder } = event;

    updateTableLazyState({
      sortField,
      sortOrder,
    });
  };

  const onSearch = async (searchTerm) => {
    updateTableLazyState({
      first: 0,
      page: 1,
      filters: {
        ...tableLazyState.filters,
        /* eslint-disable camelcase */
        search_term: searchTerm,
        /* eslint-enable camelcase */
      },
    });
  };

  const loadProgramsTable = useCallback(async () => {
    setLoading(true);
    const requestParams = {
      page: tableLazyState.page,
      pageSize: tableLazyState.rows,
      sortField: tableLazyState.sortField,
      sortOrder: tableLazyState.sortOrder,
      ...tableLazyState.filters
    };

    const programDataWrapper = apiWrapper(() =>
      apiAxios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/admin/programs`, {
        params: requestParams
      })
    );

    try {
      const response = await programDataWrapper.read();
      if (response?.data) {
        setPrograms(response.data?.programs || []);
        setProgramRecordCount(response.data?.programs.length || 0);
      }
      setLoading(false);
    } catch (promiseOrError) {
      if (promiseOrError instanceof Promise) {
        promiseOrError.then(async () => {
          try {
            const response = await programDataWrapper.read();
            if (response?.data) {
              setPrograms(response.data?.programs || []);
              setProgramRecordCount(response.data?.programs.length || 0);
            }
          } catch (error) {
            setPrograms([]);
            setProgramRecordCount(0);
            ShowAlert("warning", error ?? noProgramsError);
          } finally {
            setLoading(false);
          }
        });
      } else {
        setPrograms([]);
        setProgramRecordCount(0);
        setLoading(false);
        ShowAlert("warning", noProgramsError);
      }
    }
  }, [tableLazyState]);

  useEffect(() => {
    loadProgramsTable();
  }, [loadProgramsTable]);

  const sortedPrograms = sortHelper(programs, tableLazyState.sortField, tableLazyState.sortOrder);

  const leftToolbarHeaderTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <h1>Programs</h1>
      </div>
    );
  };

  const leftToolbarOptionsTemplate = () => {
    return (
      <SearchFilter onSearch={onSearch} />
    );
  };

  const rightToolbarOptionsTemplate = () => {
    return (
      <Button
        className="has-icon"
        label="New Program"
        icon={(options) => <FontAwesomeIcon icon={["fas", "plus"]} {...options.iconProps} /> }
        rounded
        onClick={() => navigate("/admin/programs/create")}
      />
    );
  };

  return (
    <>
      <Header />
      <main>
        <div className="container row p-4">
          <LeftMenu />
          <div className="col-10">
            <div className="card page-content">
              <div className="card-body">
                <Toolbar start={leftToolbarHeaderTemplate}></Toolbar>
                <Toolbar className="mb-3" start={leftToolbarOptionsTemplate} end={rightToolbarOptionsTemplate}></Toolbar>
                <DataTable
                  className="mx-3"
                  ref={dataTableReference}
                  value={sortedPrograms}
                  dataKey="pk"
                  lazy
                  removableSort
                  paginator
                  rows={tableLazyState.rows}
                  rowsPerPageOptions={[25, 50, 100]}
                  paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  first={tableLazyState.first}
                  totalRecords={programRecordCount}
                  onPage={onPage}
                  onSort={onSort}
                  sortField={tableLazyState.sortField}
                  sortOrder={tableLazyState.sortOrder}
                  loading={loading}
                >
                  <Column field="name" header="Program Name" sortable style={{ minWidth: "15rem" }}></Column>
                  <Column field="buyer.display_name" header="Buyer" sortable style={{ minWidth: "10rem" }}></Column>
                  <Column field="program_fee.platform_fee_percentage" header="Platform Remuneration" body={(rowData) => formatPercentage(rowData.program_fee.platform_fee_percentage)} sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="program_fee.program_fee_amount" header="Program Fee Amount" body={(rowData) => formatCurrency(rowData.program_fee.program_fee_amount, rowData.currency)} sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="program_fee.financial_agent_remuneration_percentage" header="Financial Remuneration" body={(rowData) => formatPercentage(rowData.program_fee.financial_agent_remuneration_percentage)} sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="status" header="Status" body={(rowData) => formatStatus(rowData.status)} sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column body={(rowData) => AdminProgramCrudActionsBodyTemplate(rowData.pk)} exportable={false} style={{ minWidth: "8rem" }}></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ListProgram;
