import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { StatusCodes } from "http-status-codes";

import { isEmpty } from "../../helpers/isEmptyHelpers.jsx";
import ShowAlert from "../../utils/Alert.jsx";
import { timers } from "../../constants.jsx";

function ResetPassword() {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [onResetSuccessCalled, setOnResetSuccessCalled] = useState(false);
  const unknownError = "An unknown error occurred";

  // The doubleCallFunc parameter is used to prevent this function from being called twice
  const handleResetSuccess = (doubleCallFunc) => {
    if (onResetSuccessCalled) { return; };

    doubleCallFunc(true);
    navigate("/login");
  };

  const onSubmit = (data) => {
    const formattedData = new URLSearchParams(data).toString();
    axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/password/reset`, formattedData)
      .then((response) => {
        const resetResponse = response.data;
        ShowAlert("success", resetResponse?.message, () => {
          handleResetSuccess(setOnResetSuccessCalled);
        });
        if (!isEmpty(data)) {
          setTimeout(() => {
            handleResetSuccess(setOnResetSuccessCalled);
          }, timers.LONG_DELAY);
        } else {
          ShowAlert("error", resetResponse?.error);
        }
      })
      .catch((error) => {
        if (error.response.status === StatusCodes.TEMPORARY_REDIRECT) {
          ShowAlert("error", error?.response?.data?.error || unknownError, () => { handleResetSuccess(setOnResetSuccessCalled); });
          setTimeout(() => {
            handleResetSuccess(setOnResetSuccessCalled);
          }, timers.LONG_DELAY);
        } else {
          ShowAlert("error", error?.response?.data?.error || unknownError);
        }
      });
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="container-sm">
          <div className="text-center">
            <img src="/images/swiftly-full-logo.png" alt="Logo" width={520} />
            <h2 className="">Forgot your password?</h2>
            <p className="lead">
              Enter the email address associated with your account, and
              we&#39;ll email you a link to reset your password.
            </p>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row row mb-4 text-end">
                <div className="form-group col-md-12 mt-4">
                  <InputText
                    id="email"
                    name="email"
                    placeholder="Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                    className={`w-full ${errors.email ? "p-invalid" : ""}`}
                  />
                  {errors.email && (
                    <small className="p-error">{errors.email.message}</small>
                  )}
                  <span>
                    <Link to={"/login"} onClick={() => {}}>
                      back to login
                    </Link>
                  </span>
                </div>
                <div className="form-group col-md-12 mt-4">
                  <Button
                    className="w-full"
                    type="submit"
                    label="Send reset link"
                    rounded
                    severity="primary"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
