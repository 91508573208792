import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import formatCurrency from "../helpers/formatCurrency.jsx";
import formatStatus from "../helpers/formatStatus.jsx";
import formatPercentage from "../helpers/formatPercentage.jsx";

library.add(fas);

FinancierProgramCard.propTypes = {
  pk: PropTypes.number,
  name: PropTypes.string,
  estimatedValueAmount: PropTypes.number,
  iconUrl: PropTypes.string,
  status: PropTypes.string,
  remunerationPercentage: PropTypes.string,
  currency: PropTypes.string,
};

function FinancierProgramCard({ pk, name, estimatedValueAmount, iconUrl, status, remunerationPercentage, currency }) {
  const navigate = useNavigate();

  return (
    <div className="col-12 p-1">
      <div className="card">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12 my-2">
            <div className="row align-items-center">
              <div className="col-2">
                <Avatar image={iconUrl} className="mr-3" />
              </div>
              <div className="col-10 d-flex justify-content-between">
                <h5 className="m-0">{name}</h5>
                {status && formatStatus(status)}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 my-2">
            <div className="row align-items-center">
              <div className="col-6">
                <h5 className="m-0">{formatCurrency(estimatedValueAmount, currency)}</h5>
                <small>Estimated value</small>
              </div>
              {remunerationPercentage && (
                <div className="col-6 text-start border-1 border-start">
                  <h5 className="m-0">
                    {formatPercentage(remunerationPercentage)}
                  </h5>
                  <small>Investor Remuneration Percentage</small>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 text-end my-2">
            <Button
              label="View invoices"
              iconPos="right"
              icon={(options) => (
                <FontAwesomeIcon
                  icon={["fas", "chevron-right"]}
                  {...options.iconProps}
                />
              )}
              rounded
              outlined
              severity="grey"
              onClick={() => navigate(`/financier/programs/${pk}/invoices`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancierProgramCard;
