import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const userStore = create(
  persist(
    (set) => ({
      user: {
        userType: "",
        entityLegalName: "",
        fullName: "",
      },
      setUser: (responseData) => set({
        user: {
          userType: responseData.user_type,
          entityLegalName: responseData.entity.legal_name,
          fullName: responseData.user.full_name,
        },
      }),
      clearUser: () => set({
        user: {
          userType: "",
          entityLegalName: "",
          fullName: "",
        },
      }),
    }),
    {
      name: "user-storage",
      getStorage: createJSONStorage(() => localStorage),
    }
  )
);

export default userStore;
