import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatCurrency from "../helpers/formatCurrency.jsx";

SupplierStatCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  currency: PropTypes.string,
};

function SupplierStatCard({ icon, title, value, currency }) {
  const getIconClass = (iconText) => {
    switch (iconText) {
      case "number":
        return "list-ol";
      case "currency":
        return "coins";
      default:
        return "chart-simple";
    }
  };
  const FormatDigitByType = (value, type) => {
    switch (type) {
      case "currency":
        return formatCurrency(value, currency);
      default:
        return value;
    }
  };

  return (
    <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
      <div className="card stat-card">
        <div className="row">
          <div className="col-2">
            <div className="icon-container">
              <FontAwesomeIcon icon={getIconClass(icon)} className="fas icon" />
            </div>
          </div>
          <div className="col-10">
            <div className="text-container">
              <p className="stat-title">{title}</p>
              <p className="stat-value">{FormatDigitByType(value, icon)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupplierStatCard;
