import { DataView } from "primereact/dataview";
import { PropTypes } from "prop-types";
import SupplierCard from "../cards/SupplierCard.jsx";
import SupplierProgramCard from "../cards/SupplierProgramCard.jsx";
import FinancierProgramCard from "../cards/FinancierProgramCard.jsx";

ListEntityItems.propTypes = {
  entities: PropTypes.array,
  entityType: PropTypes.string,
};

function ListEntityItems({ entities, entityType }) {
  const itemTemplate = (entity) => {
    switch (entityType) {
      case "financier_program":
        return (
          <FinancierProgramCard
            key={entity.pk}
            pk={entity.pk}
            name={entity.name}
            estimatedValueAmount={entity.estimated_value_amount}
            iconUrl={entity.icon_url}
            status={entity.status}
            remunerationPercentage={entity.program_fee?.financial_agent_remuneration_percentage}
            currency={entity.currency}
          />
        );
      case "supplier":
        return (
          <SupplierCard
            key={entity.tax_id}
            pk={entity.pk}
            legalName={entity.legal_name}
            taxId={entity.tax_id}
            totalInvoicesPendingAmount={entity.total_invoices_pending_amount}
            currency={entity.currency}
            iconUrl={entity.icon_url}
            programName={entity.program_name}
          />
        );
      case "supplier_program":
        return (
          <SupplierProgramCard
            key={entity.pk}
            pk={entity.pk}
            name={entity.name}
            buyerTaxId={entity.buyer?.tax_id}
            buyerDisplayName={entity.buyer?.display_name}
            totalInvoicesAmount={entity.total_invoices_amount}
            currency={entity.currency}
            iconUrl={entity.icon_url}
            status={entity.status}
          />
        );
    }
  };

  const listTemplate = (entities) => {
    return <div className="row grid grid-nogutter">{entities.map((entity) => itemTemplate(entity))}</div>;
  };

  return (
    <DataView value={entities} listTemplate={listTemplate} layout="list" />
  );
};

export default ListEntityItems;
