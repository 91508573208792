import { useState, useRef } from "react";
import { PropTypes } from "prop-types";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconField } from "primereact/iconfield";
import { debounceDelay } from "../constants.jsx";

SearchFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default function SearchFilter({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");
  const searchTimerRef = useRef(null);

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    
    if (searchTimerRef.current) {
      clearTimeout(searchTimerRef.current);
    }

    searchTimerRef.current = setTimeout(() => {
      onSearch(value);
    }, debounceDelay);
  };

  return (
    <div className="col-12 col-md-auto mb-2 mb-md-0 p-0">
      <IconField iconPosition="left" className="m-1">
        <FontAwesomeIcon 
          className="p-input-icon" 
          icon={["fas", "magnifying-glass"]} 
        />
        <InputText
          className="w-full"
          type="search"
          value={searchTerm}
          onChange={handleChange}
          placeholder="Search"
        />
      </IconField>
    </div>
  );
}
