import { useState,  useEffect } from "react";
import apiAxios from "../../../../axios.js";
import { useParams, useNavigate } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Header from "../../../includes/Header.jsx";
import LeftMenu from "../../../includes/LeftMenu.jsx";
import ShowAlert from "../../../utils/Alert.jsx";

import { isEmpty, isNotEmpty } from "../../../helpers/isEmptyHelpers.jsx";
import { camelizeWithSpaces } from "../../../helpers/camelizeWithSpaces.jsx";
import formatCurrency from "../../../helpers/formatCurrency.jsx";

library.add(fas);

function ShowSupplier() {

  const navigate = useNavigate();
  const [supplier, setSupplier] = useState({});
  const { pk } = useParams();
  const unknownError = "An unknown error occurred";
  const noSupplierError = "No supplier found";

  useEffect(() => {
    apiAxios
      .get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/admin/suppliers/edit?pk=${pk}`)
      .then((response) => {
        const editResponse = response.data;
        if (isEmpty(editResponse)) {
          ShowAlert("warning", response?.error ?? noSupplierError);
        } else {
          setSupplier(editResponse.supplier);
        }
      })
      .catch((error) => {
        ShowAlert("error", error.response?.data?.error || unknownError);
      });
  }, [pk]);

  const leftToolbarHeaderTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <h1>Supplier</h1>
      </div>
    );
  };

  const rightToolbarHeaderTemplate = () => {
    return (
      <>
        <Button 
          className="has-icon mr-2"
          label="Edit"
          icon={(options) => <FontAwesomeIcon icon={["fas", "pencil"]}  {...options.iconProps} /> } 
          rounded
          outlined
          severity="grey"
          onClick={() => navigate(`/admin/suppliers/${pk}/edit`)}
        />      
        <Button 
          className="has-icon"
          label="Print"
          icon={(options) => <FontAwesomeIcon icon={["fas", "print"]}  {...options.iconProps} /> } 
          rounded
          outlined
          severity="grey"
          onClick={() => window.print()}
        />      
      </>
    );
  };

  const showSupplierContacts = () => {
    if (supplier.bank_account_detail?.supplier_bank_contacts?.length) {
      return supplier.bank_account_detail.supplier_bank_contacts.map((contact) => (
        <div key={contact.pk}>
          <div className="flex-1 mt-3">
            <h6>Full name</h6>
            <p>{ contact.full_name }</p>
          </div>
          <div className="flex-1 mt-3">
            <h6>Address</h6>
            <p>{ contact.address }</p>
          </div>
          <div className="flex-1 mt-3">
            <h6>Email</h6>
            <p>{ contact.email }</p>
          </div>
          <div className="flex-1 mt-3">
            <h6>Phone number</h6>
            <p>{ contact.phone_number }</p>
          </div>
        </div>
      ));
    } else {
      return <p>No contacts available</p>;
    }
  };
  
  return (
    <>
      <Header />
      <main>
        <div className="container row p-4">
          <LeftMenu />
          <div className="col-10">
            <Toolbar className="px-0" start={leftToolbarHeaderTemplate} end={rightToolbarHeaderTemplate}></Toolbar>
            <div className="card">
              <div className="card-body mt-3">
                <h5 className="underline">General info</h5>
                <div className="info-text flex flex-wrap gap-4">
                  <div className="flex-1 mt-3">
                    <h6>Supplier company name</h6>
                    <p>{ supplier.legal_name }</p>
                  </div>
                  { supplier?.status && (
                    <div className="flex-1 mt-3">
                      <h6>Status</h6>
                      <p>{ camelizeWithSpaces(supplier.status) }</p>
                    </div>
                  ) }
                  <div className="flex-1 mt-3">
                    <h6>Registration ID</h6>
                    <p>{ supplier.registration_id }</p>
                  </div>
                  <div className="flex-1 mt-3">
                    <h6>Tax ID</h6>
                    <p>{ supplier.tax_id }</p>
                  </div>
                  { supplier?.supplier_type && (
                    <div className="flex-1 mt-3">
                      <h6>Supplier type</h6>
                      <p>{ camelizeWithSpaces(supplier.supplier_type) }</p>
                    </div>
                  ) }
                  { isNotEmpty(supplier?.supplier_limit_amount) && (
                    <div className="flex-1 mt-3">
                      <h6>Supplier limit amount</h6>
                      <p>{ formatCurrency(supplier.supplier_limit_amount) }</p>
                    </div>
                  ) }
                  <div className="flex-1 mt-3">
                    <h6>Physical address</h6>
                    <p>{ supplier.physical_address }</p>
                  </div>
                  <div className="flex-1 mt-3">
                    <h6>Automatic deny message</h6>
                    <p>{ supplier.automatic_deny_error_message }</p>
                  </div>
                </div>
                <h5 className="underline mt-5">Bank account details</h5>
                <div className="info-text flex flex-wrap gap-4">
                  <div className="flex-1 mt-3">
                    <h6>Account name</h6>
                    <p>{ supplier.bank_account_detail?.account_name }</p>
                  </div>
                  <div className="flex-1 mt-3">
                    <h6>Account number</h6>
                    <p>{ supplier.bank_account_detail?.account_number }</p>
                  </div>
                  { supplier.bank_account_detail?.bank_name && (
                    <div className="flex-1 mt-3">
                      <h6>Bank name</h6>
                      <p>{ camelizeWithSpaces(supplier.bank_account_detail.bank_name) }</p>
                    </div>
                  ) }
                  <div className="flex-1 mt-3">
                    <h6>Bank branch</h6>
                    <p>{ supplier.bank_account_detail?.bank_branch }</p>
                  </div>
                  { supplier.bank_account_detail?.account_type && (
                    <div className="flex-1 mt-3">
                      <h6>Account type</h6>
                      <p>{ camelizeWithSpaces(supplier.bank_account_detail.account_type) }</p>
                    </div>
                  ) }
                </div>
                <h5 className="underline mt-5">Supplier bank contact</h5>
                <div className="info-text flex flex-wrap gap-4">
                  { showSupplierContacts() }
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ShowSupplier;
