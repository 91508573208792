import { useRef, useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import apiAxios from "../../../../axios.js";
import { currencies, statuses, timers } from "../../../constants.jsx";
import { isNotEmpty } from "../../../helpers/isEmptyHelpers.jsx";

import ShowAlert from "../../../utils/Alert.jsx";
import Header from "../../../includes/Header.jsx";
import LeftMenu from "../../../includes/LeftMenu.jsx";
import ProgramFormStepFees from "../../../forms/ProgramFormStepFees.jsx";
import ProgramFormStepProgramParameters from "../../../forms/ProgramFormStepProgramParameters.jsx";
import ProgramFormStepProgramParticipantsEdit from "../../../forms/ProgramFormStepProgramParticipantsEdit.jsx";
import LoadingFallback from "../../../utils/Loading.jsx";

library.add(fas);

function EditProgram() {
  const stepperRef = useRef(null);
  const navigate = useNavigate();
  const { pk } = useParams();
  const [financiers, setFinanciers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const unknownError = "An unknown error occurred";
  const noProgramError = "No supplier found";
  const { register, handleSubmit, setValue, getValues, control, trigger, reset, watch, setError, clearErrors, formState: { errors } } = useForm({
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
    shouldUnregister: false, // keeps the values when component is unmounted
  });

  const stepFields = {
    0: [
      "program[name]",
      "program[program_limit_amount]",
      "program[currency]",
      "program_fee[platform_fee_percentage]",
      "program_fee[program_fee_amount]",
      "program_fee[bank_charge_amount]",
      "program_fee[rebate_percentage]",
      "program_fee[financial_agent_remuneration_percentage]",
      "program_fee[financial_remuneration_percentage]",
    ],
    1: [
      "program[status]",
      "program[credit_payment_days]",
      "program[credit_request_expiration_hours]",
      "program[credit_request_lower_limit_days]",
      "program[credit_request_upper_limit_days]",
      "program[program_type]",
    ],
  };
  
  const resetAsyncForm = useCallback(async () => {
    apiAxios
      .get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/admin/programs/edit?pk=${pk}`)
      .then((response) => {
        const editResponse = response.data;
        if (isNotEmpty(editResponse)) {
          const currency = currencies.find(c => c.code === editResponse.currency).index;
          const status = statuses.general.find(s => s.name.toLowerCase() === editResponse.status).index;
          let mappedFinanciers = [];
          let mappedBuyers = [];
          let mappedSuppliers = [];
          let selectedFinanciers = [];
          let selectedSuppliers = [];
          let currentBuyer = {};
          let feeOption = "Platform Fee Percentage";
          if (isNotEmpty(editResponse?.program_fee?.program_fee_amount)) {
            feeOption = "Program Fee";
          }
          if (isNotEmpty(editResponse?.available_buyers)) {
            mappedBuyers = editResponse?.available_buyers.map(buyer => ({
              pk: buyer.pk,
              name: buyer.display_name,
            }));
          }
          if (isNotEmpty(editResponse?.buyer)) {
            currentBuyer = {
              pk: editResponse.buyer.pk,
              name: editResponse.buyer.display_name,
            };
            mappedBuyers.push(currentBuyer);
          }
          setBuyers(mappedBuyers);

          if (isNotEmpty(editResponse?.available_financiers)) {
            mappedFinanciers = editResponse?.available_financiers.map(financier => ({
              pk: financier.pk,
              name: financier.display_name,
            }));
          }
          if (isNotEmpty(editResponse?.financiers)){
            selectedFinanciers = editResponse?.financiers.map(financier => financier.pk);
            setFinanciers([...mappedFinanciers, ...editResponse.financiers.map(financier => (
              {
                pk: financier.pk,
                name: financier.display_name
              }
            ))]);
          }
          else {
            setFinanciers(mappedFinanciers);
          }

          if (isNotEmpty(editResponse?.available_suppliers)) {
            mappedSuppliers = editResponse?.available_suppliers.map(supplier => ({
              pk: supplier.pk,
              name: supplier.legal_name,
            }));
          }
          if (isNotEmpty(editResponse?.suppliers)) {
            selectedSuppliers = editResponse?.suppliers.map(supplier => supplier.pk);
            setSuppliers([...mappedSuppliers, ...editResponse.suppliers.map(supplier => (
              {
                pk: supplier.pk,
                name: supplier.legal_name
              }
            ))]);
          }
          else {
            setSuppliers(mappedSuppliers);
          }
          /* eslint-disable camelcase*/
          reset({
            program: {
              name: editResponse.name,
              program_limit_amount: editResponse.program_limit_amount,
              currency: currency,
              status: status,
              credit_payment_days: editResponse.credit_payment_days,
              credit_request_expiration_hours: editResponse.credit_request_expiration_hours,
              credit_request_upper_limit_days: editResponse.credit_request_upper_limit_days,
              credit_request_lower_limit_days: editResponse.credit_request_lower_limit_days,
            },
            program_fee: {
              platform_fee_percentage: editResponse.program_fee?.platform_fee_percentage,
              program_fee_amount: editResponse.program_fee?.program_fee_amount,
              bank_charge_amount: editResponse.program_fee?.bank_charge_amount,
              rebate_percentage: editResponse.program_fee?.rebate_percentage,
              financial_agent_remuneration_percentage: editResponse.program_fee?.financial_agent_remuneration_percentage,
              financial_remuneration_percentage: editResponse.program_fee?.financial_remuneration_percentage,
            },
            fee_type: feeOption,
            buyer_pk: editResponse.buyer?.pk,
            suppliers: selectedSuppliers,
            financiers: selectedFinanciers,
          });
          /* eslint-enable camelcase*/
          setIsLoading(false);
        } else {
          ShowAlert("warning", response?.error ?? noProgramError);
        }
      })
      .catch((error) => {
        ShowAlert("error", error?.response?.data?.error || unknownError);
      });
  },[reset, pk]);
  
  useEffect(() => {
    resetAsyncForm();
  },[resetAsyncForm]);
  
  const centerToolbarHeaderTemplate = (title) => {
    return (
      <h3>{title}</h3>
    );
  };

  const leftToolbarFooterTemplate = () => {
    return (
      <Button
        label="Back"
        outlined
        rounded
        onClick={() => {
          stepperRef.current.prevCallback();
          setActiveStep(prev => Math.max(0, prev - 1));
        }}
      />
    );
  };

  const rightToolbarFooterTemplate = () => {
    return (
      <Button
        label="Next"
        rounded
        onClick={async () => {
          const fieldsToValidate = stepFields[activeStep] || [];
          const isValid = await trigger(fieldsToValidate);
          if (isValid) {
            stepperRef.current.nextCallback();
            setActiveStep(prev => prev + 1);
          }
        }}
      />
    );
  };

  const rightSubmitToolbarFooterTemplate = () => {
    return (
      <Button
        label="Submit"
        type="submit"
        rounded
      />
    );
  };

  const onSubmit = (data) => {
    // remove other fee types from data if one is selected
    const feeType = getValues("fee_type");
    if (feeType === "Platform Fee Percentage") {
      delete data.program_fee?.program_fee_amount;
    } else if (feeType === "Program Fee") {
      delete data.program_fee?.platform_fee_percentage;
    }
    delete data.fee_type;
    delete data.program?.currency;

    apiAxios
      .patch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/admin/programs?pk=${pk}`, data)
      .then((response) => {
        const createResponse = response.data;
        if (isNotEmpty(createResponse)) {
          ShowAlert("success", "Program edited successfully");
          setTimeout(() => {
            navigate(`/admin/programs/${pk}`);
          }, timers.SHORT_DELAY); 
        } else {
          ShowAlert("warning", response?.error || unknownError);
        }
      })
      .catch((error) => {
        ShowAlert("error", error.response?.data?.error || unknownError);
      });
  };

  if (isLoading) {
    return ( <LoadingFallback /> );
  }

  return (
    <>
      <Header />
      <main>
        <div className="container row p-4">
          <LeftMenu />
          <div className="col-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stepper className="stepper-edit" ref={stepperRef} style={{ flexBasis: "50rem" }}>
                <StepperPanel 
                  header="Fees"
                >
                  <Toolbar center={centerToolbarHeaderTemplate("Fees")}></Toolbar>       
                  <div className="flex flex-column">
                    <div className="card p-4">
                      {
                        !isLoading && (
                          <ProgramFormStepFees
                            register={register}
                            errors={errors}
                            getValues={getValues}
                            setValue={setValue}
                            control={control}
                            setError={setError}
                            clearErrors={clearErrors}
                          />
                        )}
                    </div>
                  </div>
                  <Toolbar end={rightToolbarFooterTemplate}></Toolbar>  
                </StepperPanel>
                <StepperPanel 
                  header="Program Parameters"
                  icon={(options) => <FontAwesomeIcon icon={["fas", "pen"]}  {...options.iconProps} /> }
                >
                  <Toolbar center={centerToolbarHeaderTemplate("Program Parameters")}></Toolbar>   
                  <div className="flex flex-column my-3">
                    <div className="card">
                      <ProgramFormStepProgramParameters
                        register={register}
                        errors={errors}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </div>
                  </div>
                  <Toolbar start={leftToolbarFooterTemplate} end={rightToolbarFooterTemplate}></Toolbar>  
                </StepperPanel>
                <StepperPanel 
                  header="Program Participants"
                  icon={(options) => <FontAwesomeIcon icon={["fas", "user"]}  {...options.iconProps} /> }
                >
                  <Toolbar center={centerToolbarHeaderTemplate("Program Participants")}></Toolbar>  
                  <div className="flex flex-column">
                    <div className="card">
                      <ProgramFormStepProgramParticipantsEdit
                        register={register}
                        errors={errors}
                        getValues={getValues}
                        setValue={setValue}
                        control={control}
                        financiers={financiers}
                        buyers={buyers}
                        suppliers={suppliers}
                      />
                    </div>
                  </div>
                  <Toolbar start={leftToolbarFooterTemplate} end={rightSubmitToolbarFooterTemplate}></Toolbar>  
                </StepperPanel>
              </Stepper>
            </form>
          </div>
        </div>
      </main>
    </>
  );
}

export default EditProgram;
