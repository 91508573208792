import { useState,  useEffect } from "react";
import apiAxios from "../../../../axios.js";
import { useParams, useNavigate } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Header from "../../../includes/Header.jsx";
import LeftMenu from "../../../includes/LeftMenu.jsx";
import ShowAlert from "../../../utils/Alert.jsx";

import { isEmpty, isNotEmpty } from "../../../helpers/isEmptyHelpers.jsx";
import formatCurrency from "../../../helpers/formatCurrency.jsx";

library.add(fas);

function ShowProgram() {

  const navigate = useNavigate();
  const [program, setProgram] = useState({});
  const { pk } = useParams();
  const unknownError = "An unknown error occurred";
  const noProgramError = "No program found";

  useEffect(() => {
    apiAxios
      .get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/admin/programs/edit?pk=${pk}`)
      .then((response) => {
        const editResponse = response.data;
        if (isEmpty(editResponse)) {
          ShowAlert("warning", response?.error ?? noProgramError);
        } else {
          setProgram(editResponse);
        }
      })
      .catch((error) => {
        ShowAlert("error", error.response?.data?.error || unknownError);
      });
  }, [pk]);

  const leftToolbarHeaderTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <h1>{ program.name }</h1>
      </div>
    );
  };

  const rightToolbarHeaderTemplate = () => {
    return (
      <>
        <Button 
          className="has-icon mr-2"
          label="Edit"
          icon={(options) => <FontAwesomeIcon icon={["fas", "pencil"]}  {...options.iconProps} /> } 
          rounded
          outlined
          severity="grey"
          onClick={() => navigate(`/admin/programs/${pk}/edit`)}
        />      
        <Button 
          className="has-icon"
          label="Print"
          icon={(options) => <FontAwesomeIcon icon={["fas", "print"]}  {...options.iconProps} /> } 
          rounded
          outlined
          severity="grey"
          onClick={() => window.print()}
        />      
      </>
    );
  };
  
  const showSuppliers = () => {
    if (program?.suppliers?.length) {
      return program.suppliers.map((supplier) => (
        <p key={supplier.pk}>{supplier.legal_name}</p>
      ));
    } else {
      return <p>No suppliers available</p>;
    }
  };

  const showFinanciers = () => {
    if (program?.financiers?.length) {
      return program.financiers.map((financier) => (
        <p key={financier.pk}>{financier.display_name}</p>
      ));
    } else {
      return <p>No financiers available</p>;
    }
  };


  return (
    <>
      <Header />
      <main>
        <div className="container row p-4">
          <LeftMenu />
          <div className="col-10">
            <Toolbar className="px-0" start={leftToolbarHeaderTemplate} end={rightToolbarHeaderTemplate}></Toolbar>
            <div className="card">
              <div className="card-body mt-3">
                <h5 className="underline">General info</h5>
                <div className="info-text flex flex-wrap gap-4">
                  <div className="flex-1 mt-3">
                    <h6>Currency</h6>
                    <p>{ program.currency }</p>
                  </div>
                  <div className="flex-1 mt-3">
                    <h6>Program Limit</h6>
                    <p>{ formatCurrency(program.program_limit_amount, program.currency) }</p>
                  </div>
                  <div className="flex-1 mt-3">
                    <h6>Status</h6>
                    <p>{ program.status }</p>
                  </div>
                  <div className="flex-1 mt-3">
                    <h6>Suppliers</h6>
                    { showSuppliers() }
                  </div>
                  <div className="flex-1 mt-3">
                    <h6>Financiers</h6>
                    { showFinanciers() }
                  </div>
                </div>
                <h5 className="underline mt-5">The investors</h5>
                <div className="info-text flex flex-wrap gap-4">
                  <div className="flex-1 mt-3">
                    <h6>Investor</h6>
                    {program?.buyer?.display_name ? (
                      <p>{program.buyer.display_name}</p>
                    ) : (
                      <p>No buyer available</p>
                    )}
                  </div>
                  { isNotEmpty(program?.program_fee?.platform_fee_percentage) && (
                    <div className="flex-1 mt-3">
                      <h6>Platform fee percentage</h6>
                      <p>{ program.program_fee.platform_fee_percentage }%</p>
                    </div>
                  ) }
                  { isNotEmpty(program?.program_fee?.program_fee_amount) && (
                    <div className="flex-1 mt-3">
                      <h6>Program fee</h6>
                      <p>{ program.program_fee.program_fee_amount }</p>
                    </div>
                  ) }
                  { program?.program_fee?.bank_charge_amount && (
                    <div className="flex-1 mt-3">
                      <h6>Bank charge</h6>
                      <p>{ formatCurrency(program.program_fee.bank_charge_amount, program.currency) }</p>
                    </div>
                  ) }
                  { program?.program_fee?.rebate_percentage && (
                    <div className="flex-1 mt-3">
                      <h6>Rebate percentage</h6>
                      <p>{ program.program_fee.rebate_percentage }%</p>
                    </div>
                  ) }
                  { program?.program_fee?.financial_agent_remuneration_percentage && (
                    <div className="flex-1 mt-3">
                      <h6>Financial agent remuneration</h6>
                      <p>{ program.program_fee.financial_agent_remuneration_percentage }%</p>
                    </div>
                  ) }
                  { program?.program_fee?.financial_remuneration_percentage && (
                    <div className="flex-1 mt-3">
                      <h6>Financial remuneration</h6>
                      <p>{ program.program_fee.financial_remuneration_percentage }%</p>
                    </div>
                  ) }
                </div>
                <h5 className="underline mt-5"> </h5>
                <div className="info-text flex flex-wrap gap-4">
                  { isNotEmpty(program?.credit_payment_days) && (
                    <div className="flex-1 mt-3">
                      <h6>Credit Payment Days</h6>
                      <p>{ program.credit_payment_days }</p>
                    </div>
                  ) }
                  { isNotEmpty(program?.credit_request_expiration_hours) && (
                    <div className="flex-1 mt-3">
                      <h6>Credit request expiration, hours</h6>
                      <p>{ program.credit_request_expiration_hours }</p>
                    </div>
                  ) }
                  { program?.credit_request_lower_limit_days && (
                    <div className="flex-1 mt-3">
                      <h6>Credit request lower limit, days</h6>
                      <p>{ program.credit_request_lower_limit_days }</p>
                    </div>
                  ) }
                  { program?.credit_request_upper_limit_days && (
                    <div className="flex-1 mt-3">
                      <h6>Credit request upper limit, days</h6>
                      <p>{ program.credit_request_upper_limit_days }</p>
                    </div>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ShowProgram;
