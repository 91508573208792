import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const notificationStore = create(
  persist(
    (set) => ({
      notifications: [],
      setNotifications: (newNotifications) => set({ notifications: newNotifications }),
      addNotification: (notification) => set((state) => ({
        notifications: [...state.notifications, notification],
      })),
      removeNotification: (pk) => set((state) => ({
        notifications: state.notifications.filter((notification) => notification.pk !== pk),
      })),
      clearNotifications: () => set({ notifications: [] }),
    }),
    {
      name: "notification-storage",
      getStorage: createJSONStorage(() => localStorage),
    }
  )
);

export default notificationStore;
