import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { camelizeWithSpaces } from "../helpers/camelizeWithSpaces.jsx";
import formatCurrency from "../helpers/formatCurrency.jsx";

library.add(fas);

SupplierInvoiceFee.propTypes = {
  invoice: PropTypes.object.isRequired,
  earlyPaymentFees: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      rate: PropTypes.string,
      term: PropTypes.string.isRequired,
    })
  ),
};

function SupplierInvoiceFee({ invoice, earlyPaymentFees }) {
  return (
    <div>
      {earlyPaymentFees.map((fee) => (
        <div key={fee.name}>
          <div className="d-flex justify-content-between my-3">
            <div>
              <h6>{camelizeWithSpaces(fee.name)}</h6>
            </div>
            <p className="bold-sm-light">{fee.rate}</p>
          </div>
          <div className="d-flex justify-content-between my-3">
            <h6>{fee.term}</h6>
            <div className="d-flex">
              <FontAwesomeIcon className="mx-2 my-1" icon={["fas", "minus-circle"]} />
              <p className="bold-sm-light p-0 underline">{formatCurrency(fee.amount, invoice.currency)}</p>
            </div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default SupplierInvoiceFee;
