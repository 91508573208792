import { useState } from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { isEmpty, isNotEmpty } from "../helpers/isEmptyHelpers.jsx";

library.add(fas);

ProgramFormStepProgramParticipantsEdit.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  financiers: PropTypes.array.isRequired,
  buyers: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
};

function ProgramFormStepProgramParticipantsEdit({ errors, control, getValues, setValue, buyers, suppliers, financiers }) {

  const retrievedSuppliers = getValues("suppliers");
  const retrievedFinanciers = getValues("financiers");
  const [selectedFinanciers, setSelectedFinanciers] = useState(retrievedFinanciers || []);
  const [selectedSuppliers, setSelectedSuppliers] = useState(retrievedSuppliers || []);
  
  const getSelectedSuppliers = () => {
    return suppliers.filter(supplier => selectedSuppliers.includes(supplier.pk));
  };

  const getNonSelectedSuppliers = () => {
    return suppliers.filter(supplier => !selectedSuppliers.includes(supplier.pk));
  };

  const handleSupplierChange = (e) => {
    const selectedValues = e.value;
    setSelectedSuppliers((prevSelectedSuppliers) => {
      const updatedSelection = [
        ...new Set([...prevSelectedSuppliers, ...selectedValues]),
      ];
      return updatedSelection;
    });
  };

  const removeSelectedSuppliers = (supplierPk) => {
    if (isEmpty(supplierPk)) {
      setValue("suppliers", []);
      setSelectedSuppliers([]);
    } else {
      const newFormValues = selectedSuppliers.filter(supplier => supplier !== supplierPk);
      setValue("suppliers", newFormValues);
      setSelectedSuppliers(newFormValues);
    }
  };

  const getSelectedFinanciers = () => {
    return financiers.filter(financier => selectedFinanciers.includes(financier.pk));
  };

  const getNonSelectedFinanciers = () => {
    return financiers.filter(financier => !selectedFinanciers.includes(financier.pk));
  };

  const handleFinancierChange = (e) => {
    const selectedValues = e.value;
    setSelectedFinanciers((prevSelectedFinancier) => {
      const updatedSelection = [
        ...new Set([...prevSelectedFinancier, ...selectedValues]),
      ];
      return updatedSelection;
    });
  };

  const removeSelectedFinanciers = (financierPk) => {
    if (isEmpty(financierPk)) {
      setValue("financiers", []);
      setSelectedFinanciers([]);
    } else {
      const newFormValues = selectedFinanciers.filter(financier => financier !== financierPk);
      setValue("financiers", newFormValues);
      setSelectedFinanciers(newFormValues);
    }
  };
  
  return (
    <>
      <div className="form-row row mb-4">
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="buyer" className="form-label">Buyer</label>
          <Controller
            id="buyer"
            name="buyer_pk"
            control={control}
            rules={{ required: "Buyer is required" }}
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                value={field.value}
                options={buyers}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                optionLabel="name"
                optionValue="pk"
                placeholder="Select a Buyer"
                className={`w-full ${fieldState.error ? "p-invalid" : ""}`}
              />
            )}
          />
          {errors.buyer_pk && (
            <small className="p-error">{errors.buyer_pk.message}</small>
          )}
        </div>
      </div>
      <h5 className="underline mt-3">Financier</h5>
      <small className="text-muted d-block">
        Add financiers by selecting from the list below. Click on a financier&#39;s name to remove it, or click the trash icon to clear the entire selection.
      </small>
      <div className="form-row row mb-4 mt-3">
        <div className="form-group col-md-6 col-6">
          <label htmlFor="financiers" className="form-label">Financiers</label>
          <Controller
            id="financiers"
            name="financiers"
            control={control}
            rules={{ required: "Financiers are required" }}
            render={({ field, fieldState }) => (
              <MultiSelect
                {...field}
                value={field.value}
                options={getNonSelectedFinanciers()}
                onChange={(e) => {
                  field.onChange(e.value);
                  handleFinancierChange(e);
                }}
                optionLabel="name"
                optionValue="pk"
                placeholder="Select financiers"
                className={`w-full md:w-20rem ${fieldState.error ? "p-invalid" : ""}`}
                filter
                maxSelectedLabels={0}
              />
            )}
          />
          {errors.financiers && (
            <small className="p-error">{errors.financiers.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 col-6">
          <div className="d-flex justify-content-between items-center">
            <label htmlFor="financiers" className="form-label">Selected Financiers</label>
            {isNotEmpty(selectedFinanciers) && (
              <Button
                icon={(options) => <FontAwesomeIcon icon={["fas", "trash-can"]} {...options.iconProps} />}
                title="Remove All"
                size="small"
                severity="danger"
                onClick={() => removeSelectedFinanciers()}
              />
            )}
          </div>
          {getSelectedFinanciers().map((financier) => {
            return (
              <div key={financier.pk}>
                <Button 
                  className="w-full"
                  icon={(options) => <FontAwesomeIcon icon={["fas", "trash-can"]}  {...options.iconProps} /> }
                  label={financier.name}
                  iconPos="right"
                  outlined
                  rounded
                  severity="grey" 
                  onClick={() => removeSelectedFinanciers(financier.pk)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <h5 className="underline mt-3">Supplier</h5>
      <small className="text-muted d-block">
        Add suppliers by selecting from the list below. Click on a supplier&#39;s name to remove it, or click the trash icon to clear the entire selection.
      </small>
      <div className="form-row row mb-4 mt-3">
        <div className="form-group col-md-6 col-6">
          <label htmlFor="suppliers" className="form-label">Suppliers</label>
          <Controller
            id="suppliers"
            name="suppliers"
            control={control}
            rules={{ required: "Suppliers are required" }}
            render={({ field, fieldState }) =>
              <MultiSelect
                {...field}
                value={field.value}
                options={getNonSelectedSuppliers()}
                onChange={(e) => {
                  field.onChange(e.value);
                  handleSupplierChange(e);
                }}
                optionLabel="name"
                optionValue="pk"
                placeholder="Select suppliers"
                className={`w-full md:w-20rem ${fieldState.error ? "p-invalid" : ""}`}
                filter
                maxSelectedLabels={0}
              />
            }
          />
          {errors.suppliers && (
            <small className="p-error">{errors.suppliers.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 col-6">
          <div className="d-flex justify-content-between items-center">
            <label htmlFor="suppliers" className="form-label">Selected Suppliers</label>
            {isNotEmpty(selectedSuppliers) && (
              <Button
                icon={(options) => <FontAwesomeIcon icon={["fas", "trash-can"]} {...options.iconProps} />}
                title="Remove All"
                size="small"
                severity="danger"
                onClick={() => removeSelectedSuppliers()}
              />
            )}
          </div>
          {getSelectedSuppliers().map((supplier) => {
            return (
              <div key={supplier.pk}>
                <Button 
                  className="w-full"
                  icon={(options) => <FontAwesomeIcon icon={["fas", "trash-can"]}  {...options.iconProps} /> }
                  label={supplier.name}
                  iconPos="right"
                  outlined
                  rounded
                  severity="grey" 
                  onClick={() => removeSelectedSuppliers(supplier.pk)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProgramFormStepProgramParticipantsEdit;
