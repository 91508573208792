import { useCallback, useEffect } from "react";
import apiAxios from "../../../../axios.js";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { timers, statuses, companyTypes, accountTypes, bankNames } from "../../../constants.jsx";
import { isNotEmpty } from "../../../helpers/isEmptyHelpers.jsx";
import ShowAlert from "../../../utils/Alert.jsx";
import Header from "../../../includes/Header.jsx";
import LeftMenu from "../../../includes/LeftMenu.jsx";
import SupplierForm from "../../../forms/SupplierForm.jsx";

library.add(fas);

function EditSupplier() {
  const navigate = useNavigate();
  const { pk } = useParams();
  const unknownError = "An unknown error occurred";
  const noSupplierError = "No supplier found";
  const { register, handleSubmit, setValue, control, watch, reset, formState: { errors } } = useForm({
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const resetAsyncForm = useCallback(async () => {
    apiAxios
      .get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/admin/suppliers/edit?pk=${pk}`)
      .then((response) => {
        const editResponse = response.data;
        if (isNotEmpty(editResponse)) {
          const status = statuses.general.find(s => s.name.toLowerCase() === editResponse.supplier?.status).index;
          const supplierType = companyTypes.find(c => c.code.toLowerCase() === editResponse.supplier?.supplier_type).index;
          const accountType = accountTypes.find(a => a.name.toLowerCase() === editResponse.supplier?.bank_account_detail?.account_type).index;
          const bankName = bankNames.find(b => b.code === editResponse.supplier?.bank_account_detail?.bank_name).index;
          /* eslint-disable camelcase*/
          reset({
            supplier: {
              legal_name: editResponse.supplier.legal_name,
              supplier_type: supplierType,
              registration_id: editResponse.supplier.registration_id,
              physical_address: editResponse.supplier.physical_address,
              tax_id: editResponse.supplier.tax_id,
              automatic_deny_error_message: editResponse.supplier.automatic_deny_error_message,
              status: status,
              supplier_limit_amount: editResponse.supplier?.supplier_limit_amount,
            },
            bank_account_detail: {
              account_name: editResponse.supplier.bank_account_detail?.account_name,
              account_number: editResponse.supplier.bank_account_detail?.account_number,
              account_type: accountType,
              bank_name: bankName,
              bank_branch: editResponse.supplier.bank_account_detail?.bank_branch,
            },
            supplier_bank_contact: {
              address: editResponse.supplier.bank_account_detail?.supplier_bank_contacts[0]?.address,
              email: editResponse.supplier.bank_account_detail?.supplier_bank_contacts[0]?.email,
              full_name: editResponse.supplier.bank_account_detail?.supplier_bank_contacts[0]?.full_name,
              phone_number: editResponse.supplier.bank_account_detail?.supplier_bank_contacts[0]?.phone_number,
            }
          });
          /* eslint-enable camelcase*/
        } else {
          ShowAlert("warning", response?.error ?? noSupplierError);
        }
      })
      .catch((error) => {
        ShowAlert("error", error?.response?.data?.error || unknownError);
      });
  }, [reset, pk]);
  
  useEffect(() => {
    resetAsyncForm();
  },[resetAsyncForm]);

  const onSubmit = (data) => {
    apiAxios
      .patch(`${process.env.REACT_APP_BASE_API_URL}/api/v1/admin/suppliers?pk=${pk}`, data)
      .then((response) => {
        var createResponse = response.data;
        if (isNotEmpty(createResponse)) {
          ShowAlert("success", "Supplier edited successfully");
          setTimeout(() => {
            navigate("/admin/suppliers");
          }, timers.redirect);
        } else {
          ShowAlert("warning", response?.error || unknownError);
        }
      })
      .catch((error) => {
        ShowAlert("error", error.response?.data?.error || unknownError);
      });
  };

  const centerToolbarHeaderTemplate = () => {
    return (
      <h1>Edit Supplier</h1>
    );
  };

  const rightToolbarFooterTemplate = () => {
    return (
      <Button
        icon={(options) => <FontAwesomeIcon icon={["fas", "pencil"]}  {...options.iconProps} /> }
        type="submit"
        label="Update supplier"
        rounded
        severity="primary"
      />
    );
  };

  return (
    <>
      <Header />
      <main>
        <div className="container row p-4">
          <LeftMenu />
          <div className="col-10">
            <Toolbar center={centerToolbarHeaderTemplate}></Toolbar>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card">
                <div className="card-body">
                  <SupplierForm
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    control={control}
                    watch={watch}
                  />
                </div>
              </div>
              <Toolbar className="px-0" end={rightToolbarFooterTemplate}></Toolbar>
            </form>
          </div>
        </div>
      </main>
    </>
  );
}

export default EditSupplier;
