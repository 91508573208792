import { Route } from "react-router-dom";
import Error from "../pages/common/Error.jsx";

const errorRoutes = [
  <Route key="404" path="*" element={
    <Error 
      start="Oops! Page not found"
      code="404"
      end="We are sorry, but the page you requested was not found"/>
  } />,
];

export default errorRoutes;
