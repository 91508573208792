function ProgramFormStepProgramParticipants(){
  
  return (
    <>
      <div className="form-row row mb-4">
        <div className="form-group col-md-12 mt-4">
          <p className="text-center">The financiers, buyer, and suppliers can be added once the program is created. Please use edit to add those members</p>
        </div>
      </div>
    </>
  );
};

export default ProgramFormStepProgramParticipants;
