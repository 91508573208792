import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { statuses } from "../constants.jsx";
import { isNotEmpty } from "../helpers/isEmptyHelpers.jsx";
import { validateNumeric } from "../helpers/validationHelpers.jsx";

ProgramFormStepProgramParameters.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

function ProgramFormStepProgramParameters({ register, errors, watch, control, setValue, setError, clearErrors }) {
  const creditRequestUpperLimitDays = watch("program[credit_request_upper_limit_days]");
  const creditRequestLowerLimitDays = watch("program[credit_request_lower_limit_days]");
  
  return (
    <>
      <div className="form-row row mb-4">
        <div className="form-group col-md-6 mt-4">
          <label htmlFor="status" className="form-label">Status</label>
          <Controller
            id="status"
            name="program[status]"
            control={control}
            rules={{ required: "Status is required" }}
            render={({ field, fieldState }) => (
              <Dropdown
                {...field}
                value={field.value}
                options={statuses.general}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                optionLabel="name"
                optionValue="index"
                placeholder="Select Status"
                className={`w-full ${fieldState.error ? "p-invalid" : ""}`}
              />
            )}
          />
          {errors.program?.status && (
            <small className="p-error">{errors.program.status.message}</small>
          )}
        </div>
      </div>
      <div className="form-row row mb-4">
        <div className="form-group col-md-6 mt-3">
          <label htmlFor="creditPaymentDays" className="form-label">Credit payment days</label>
          <InputText
            id="creditPaymentDays"
            name="program[credit_payment_days]"
            {...register("program[credit_payment_days]", { required: "Credit payment days is required" })}
            className={`w-full ${errors.program?.credit_payment_days ? "p-invalid" : ""}`}
            placeholder="Enter number of days"
            onChange={(e) =>
              validateNumeric(
                e,
                setValue,
                setError,
                clearErrors,
                "program[credit_payment_days]"
              )
            }
          />
          {errors.program?.credit_payment_days && (
            <small className="p-error">{errors.program.credit_payment_days.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-3">
          <label htmlFor="creditRequestExpirationHours" className="form-label">Credit request expiration, hours</label>
          <InputText
            id="creditRequestExpirationHours"
            name="program[credit_request_expiration_hours]"
            {...register("program[credit_request_expiration_hours]", { required: "Credit request expiration is required" })}
            className={`w-full ${errors.program?.credit_request_expiration_hours ? "p-invalid" : ""}`}
            placeholder="Enter number of hours"
            onChange={(e) =>
              validateNumeric(
                e,
                setValue,
                setError,
                clearErrors,
                "program[credit_request_expiration_hours]"
              )
            }
          />
          {errors.program?.credit_request_expiration_hours && (
            <small className="p-error">{errors.program.credit_request_expiration_hours.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-3">
          <label htmlFor="creditRequestLowerLimitDays" className="form-label">Credit request lower limit, days</label>
          <InputText
            id="creditRequestLowerLimitDays"
            name="program[credit_request_lower_limit_days]"
            {...register("program[credit_request_lower_limit_days]",
              {
                required: "Credit request lower limit is required",
                min: { value: 2, message: "The application wide credit request lower limit is 2 days" },
                validate: (value) => {
                  if (isNotEmpty(creditRequestUpperLimitDays) && parseInt(value) > parseInt(creditRequestUpperLimitDays)) {
                    return "Credit request lower limit days should be less than the upper limit days";
                  }
                  return true;
                },
              })}
            onChange={(e) =>
              validateNumeric(
                e,
                setValue,
                setError,
                clearErrors,
                "program[credit_request_lower_limit_days]"
              )
            }
            className={`w-full ${errors.program?.credit_request_lower_limit_days ? "p-invalid" : ""}`}
            placeholder="Enter number of days"
          />
          {errors.program?.credit_request_lower_limit_days && (
            <small className="p-error">{errors.program.credit_request_lower_limit_days.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-3">
          <label htmlFor="creditRequestUpperLimitDays" className="form-label">Credit request upper limit, days</label>
          <InputText
            id="creditRequestUpperLimitDays"
            name="program[credit_request_upper_limit_days]"
            {...register("program[credit_request_upper_limit_days]",
              {
                required: "Credit request upper limit is required",
                max: { value: 180, message: "The application wide credit request upper limit is 180 days" },
                validate: (value) => {
                  if (isNotEmpty(creditRequestLowerLimitDays) && parseInt(value) < parseInt(creditRequestLowerLimitDays)){
                    return "Credit request upper limit days should be greater than the lower limit days";
                  }
                  return true;
                }
              })}
            onChange={(e) =>
              validateNumeric(
                e,
                setValue,
                setError,
                clearErrors,
                "program[credit_request_upper_limit_days]"
              )
            }
            className={`w-full ${errors.program?.credit_request_upper_limit_days ? "p-invalid" : ""}`}
            placeholder="Enter number of days"
          />
          {errors.program?.credit_request_upper_limit_days && (
            <small className="p-error">{errors.program.credit_request_upper_limit_days.message}</small>
          )}
        </div>
        <div className="form-group col-md-12">
          {isNotEmpty(creditRequestLowerLimitDays) && isNotEmpty(creditRequestUpperLimitDays) && (
            <div className="mt-3">
              <p className="text-center">A supplier will be able to request early payment {creditRequestLowerLimitDays} to {creditRequestUpperLimitDays} days from the due date.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProgramFormStepProgramParameters;
