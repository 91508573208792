import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Header from "../../includes/Header.jsx";

library.add(fas);

ErrorPage.propTypes = {
  start: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired
};

function ErrorPage({ start, code, end }) {
  const navigate = useNavigate();
  const codeNumbers = code.split("");
  
  return (
    <>
      <Header />
      <main>
        <div className="container my-4">
          <div className="card page-content">
            <div className="card-body">
              <div id="notfound">
                <div className="notfound">
                  <div className="notfound-404">
                    <h3>{start}</h3>
                    <h1><span>{codeNumbers[0]}</span><span>{codeNumbers[1]}</span><span>{codeNumbers[2]}</span></h1>
                  </div>
                  <h2>{end}</h2>
                  <div>
                    <Button 
                      icon={(options) => <FontAwesomeIcon icon={["fas", "arrow-left"]}  {...options.iconProps} /> } 
                      outlined
                      rounded
                      label="Home"
                      severity="dark"
                      onClick={() => navigate("/")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ErrorPage;
