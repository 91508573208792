import apiAxios from "../../../axios.js";
import { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import userStore from "../../../stores/UserStore.js";
import notificationStore from "../../../stores/NotificationStore.js";
import { isNotEmpty } from "../../helpers/isEmptyHelpers.jsx";
import ShowAlert from "../../utils/Alert.jsx";
import { timers } from "../../constants.jsx";
import getUserDashboard from "../../helpers/getUserDashboard.jsx";

library.add(fas);

function Login() {
  const navigate = useNavigate();
  const setUser = userStore((state) => state.setUser);
  const setNotifications = notificationStore((state) => state.setNotifications);
  const { register, handleSubmit, formState: { errors }, control } = useForm();
  const unknownError = "An unknown error occurred";
  const [onLoginSuccessCalled, setOnLoginSuccessCalled] = useState(false);

  const navigateUserToDashboard = useCallback((userType, doubleCallFun) => {
    if (onLoginSuccessCalled) { return; }
    doubleCallFun(true);
    const dashboardPath = getUserDashboard(userType);
    navigate(dashboardPath);
  }, [onLoginSuccessCalled, navigate]);

  const getNotifications = () => {
    apiAxios
      .get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/notifications`)
      .then((response) => {
        const notifications = response.data.notifications;
        if (isNotEmpty(notifications)) {
          setNotifications(notifications);
        } else {
          setNotifications([]);
        }
      })
      .catch((error) => {
        ShowAlert("error", error.response?.data?.error || unknownError);
      });
  };

  const onSubmit = (data) => {
    apiAxios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/login`, data)
      .then((response) => {
        const loginResponse = response.data;
        if (isNotEmpty(loginResponse)) {
          const userType = loginResponse.user_type;
          setUser(loginResponse);
          getNotifications();
          ShowAlert("success", loginResponse.message, () => { navigateUserToDashboard(loginResponse.user_type, setOnLoginSuccessCalled); });
          setTimeout(() => {
            navigateUserToDashboard(userType, setOnLoginSuccessCalled);
          }, timers.SHORT_DELAY);
        } else {
          ShowAlert("error", loginResponse?.error || unknownError);
        }
      })
      .catch((error) => {
        var errorResponse = error.response?.data;
        if (isNotEmpty(errorResponse?.error) && errorResponse.error.toLowerCase().includes("complete 2fa")) {
          ShowAlert("warning", errorResponse.error);
          setTimeout(() => {
            navigate(`/verification?token=${errorResponse.token}`);
          }, timers.SHORT_DELAY);
        } else {
          ShowAlert("error", error.response?.data?.error || unknownError);
        }
      });
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="container-sm">
          <div className="text-center">
            <img className="mb-4" src="/images/swiftly-full-logo.png" alt="Logo" width={520} />
            <h3 className="mt-4">Welcome to Swiftly Finance</h3>
            <p className="lead mt-2">Your early invoice payment platform</p>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row row mb-4">
                <div className="form-group col-md-12 mt-4">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <InputText
                    id="email"
                    name="email"
                    placeholder="Enter email"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={true}
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                    className={`w-full ${errors.email ? "p-invalid" : ""}`}
                  />
                  {errors.email && (
                    <small className="p-error">{errors.email.message}</small>
                  )}
                </div>
                <div className="form-group col-md-12 mt-4">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required" }}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        {...field}
                        toggleMask
                        feedback={false}
                        placeholder="Enter password"
                        className={`w-full ${fieldState.invalid ? "p-invalid" : ""}`}
                      />
                    )}
                  />
                  {errors.password && (
                    <small className="p-error">{errors.password.message}</small>
                  )}
                  <div className="form-group col-12 text-end">
                    <span>
                      <Link to={"/reset-password"} onClick={() => {}}>
                        Forgot password?
                      </Link>
                    </span>
                  </div>
                </div>
                <div className="form-group col-md-12 mt-4">
                  <Button
                    className="w-full p-button-wide"
                    icon={(options) => (
                      <FontAwesomeIcon
                        icon={["fas", "right-to-bracket"]}
                        {...options.iconProps}
                      />
                    )}
                    type="submit"
                    label="Sign In"
                    rounded
                    severity="primary"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
