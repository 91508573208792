const isEmpty = (obj) => {
  if (obj === null) {return true;} // Check for null or undefined
  if (Array.isArray(obj) || typeof obj === "string") {return obj.length === 0;} // Check for empty array or string
  if (typeof obj === "object") {return Object.keys(obj).length === 0;} // Check for empty object
  // Check for null or undefined, explicitly not considering 0 as empty
  if (obj !== 0 && (!obj || typeof obj === "undefined")) {return true;}
  return false; // Default case for other types
};

const isNotEmpty = (obj) => {
  return !isEmpty(obj);
};

export { isEmpty, isNotEmpty };
