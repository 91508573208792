import { useParams } from "react-router-dom";
import { useEffect, useState, useRef, useCallback } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Toolbar } from "primereact/toolbar";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { TabPanel, TabView } from "primereact/tabview";

import Header from "../../includes/Header.jsx";
import apiAxios from "../../../axios.js";
import { apiWrapper } from "../../common/apiWrapper.js";
import InvoiceFilterActions from "../../actions/InvoiceFilterActions.jsx";

import formatDate from "../../helpers/formatDate.jsx";
import formatCurrency from "../../helpers/formatCurrency.jsx";
import FinancierRequestActionsBodyTemplate from "../../tables/templates/FinancierRequestActionsBodyTemplate.jsx";
import FinancierViewActionsBodyTemplate from "../../tables/templates/FinancierViewActionsBodyTemplate.jsx";
import formatPercentage from "../../helpers/formatPercentage.jsx";
import sortHelper from "../../helpers/sortHelper.jsx";

library.add(fas);

function FinancierListProgramInvoice() {
  const { pk } = useParams();

  function InvoiceContent() {
    const [activeIndex, setActiveIndex] = useState(0);
    const pendingInvoicesDataTableReference = useRef(null);
    const pastInvoicesDataTableReference = useRef(null);
    const [loading, setLoading] = useState(false);
    const [totalPendingRecordCount, setTotalPendingRecordCount] = useState(0);
    const [totalPastRecordCount, setTotalPastRecordCount] = useState(0);
    const [pendingInvoices, setPendingInvoices] = useState([]);
    const [pastInvoices, setPastInvoices] = useState([]);
    const [program, setProgram] = useState({});
    const [isClearFilterVisible, setIsClearFilterVisible] = useState(false);
    const [tableLazyState, setTableLazyState] = useState({
      first: 0,
      rows: 25,
      page: 1,
      sortField: null,
      sortOrder: null,
      filters: {}
    });

    const updateTableLazyState = (newState) => {
      setTableLazyState((prevState) => ({
        ...prevState,
        ...newState,
      }));
    };

    const onPage = (event) => {
      updateTableLazyState({
        first: event.first,
        rows: event.rows,
        page: event.page + 1,
      });
    };

    const onSort = (event) => {
      const { sortField, sortOrder } = event;

      updateTableLazyState({
        sortField,
        sortOrder,
      });
    };

    const onSearch = async (searchTerm) => {
      updateTableLazyState({
        first: 0,
        page: 1,
        filters: {
          ...tableLazyState.filters,
          /* eslint-disable camelcase */
          search_term: searchTerm,
          /* eslint-enable camelcase */
        },
      });
    };

    const onDateChange = (startDate, endDate) => {
      updateTableLazyState({
        first: 0,
        page: 1,
        filters: {
          ...tableLazyState.filters,
          /* eslint-disable camelcase */
          start_date: startDate,
          end_date: endDate,
          /* eslint-enable camelcase */
        },
      });
    };

    const onAmountChange = (minAmount, maxAmount) => {
      updateTableLazyState({
        first: 0,
        page: 1,
        filters: {
          ...tableLazyState.filters,
          /* eslint-disable camelcase */
          min_amount: minAmount,
          max_amount: maxAmount,
          /* eslint-enable camelcase */
        },
      });
    };

    const loadInvoiceTable = useCallback(async () => {
      setLoading(true);
      const requestParams = {
        /* eslint-disable camelcase */
        program_pk: pk,
        /* eslint-enable camelcase */
        page: tableLazyState.page,
        pageSize: tableLazyState.rows,
        sortField: tableLazyState.sortField,
        sortOrder: tableLazyState.sortOrder,
        ...tableLazyState.filters
      };

      const invoiceDataWrapper = apiWrapper(() =>
        apiAxios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/financier/program/invoices`, {
          params: requestParams
        })
      );

      try {
        const response = await invoiceDataWrapper.read();
        if (response?.data) {
          setPendingInvoices(response.data.pending || []);
          setPastInvoices(response.data.history || []);
          setTotalPendingRecordCount(response.data.pending.length || 0);
          setTotalPastRecordCount(response.data.history.length || 0);
          setProgram(response.data.program || {});
        }
        setLoading(false);
      } catch (promiseOrError) {
        if (promiseOrError instanceof Promise) {
          promiseOrError.then(async () => {
            try {
              const response = await invoiceDataWrapper.read();
              if (response?.data) {
                setPendingInvoices(response.data.pending || []);
                setPastInvoices(response.data.history || []);
                setTotalPendingRecordCount(response.data.pending.length || 0);
                setTotalPastRecordCount(response.data.history.length || 0);
                setProgram(response.data.program || {});
              }
            } catch (error) {
              setPendingInvoices([]);
              setPastInvoices([]);
              setTotalPendingRecordCount(0);
              setTotalPastRecordCount(0);
              setProgram({});
            } finally {
              setLoading(false);
            }
          });
        } else {
          setPendingInvoices([]);
          setPastInvoices([]);
          setTotalPendingRecordCount(0);
          setTotalPastRecordCount(0);
          setProgram({});
          setLoading(false);
        }
      }
    }, [tableLazyState]);

    useEffect(() => {
      loadInvoiceTable();
    }, [loadInvoiceTable]);

    const sortedPendingInvoices = sortHelper(pendingInvoices, tableLazyState.sortField, tableLazyState.sortOrder);
    const sortedPastInvoices = sortHelper(pastInvoices, tableLazyState.sortField, tableLazyState.sortOrder);

    const exportCSV = () => {
      if (activeIndex === 0) {
        pendingInvoicesDataTableReference.current.exportCSV();
      } else if (activeIndex === 1) {
        pastInvoicesDataTableReference.current.exportCSV();
      }
    };

    const leftToolbarHeaderTemplate = () => {
      return (
        <div className="flex flex-wrap gap-2">
          <h1>{program.name}</h1>
        </div>
      );
    };

    const rightToolbarOptionsTemplate = () => {
      return (
        <Button
          className="has-icon"
          label="Export table"
          icon={(options) => <FontAwesomeIcon icon={["fas", "file-export"]} {...options.iconProps} />}
          rounded
          severity="dark"
          onClick={exportCSV}
        />
      );
    };

    return (
      <div className="card page-content">
        <div className="card-body">
          <Toolbar
            className="mb-4"
            start={leftToolbarHeaderTemplate}
            end={
              <InvoiceFilterActions
                onSearch={onSearch}
                onDateChange={onDateChange}
                onAmountChange={onAmountChange}
                updateTableLazyState={updateTableLazyState}
                setIsClearFilterVisible={setIsClearFilterVisible}
                isClearFilterVisible={isClearFilterVisible}
              />}
          />
          <div className="position-relative">
            <TabView
              className="m-4"
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              <TabPanel
                header="Pending invoices"
                leftIcon={<FontAwesomeIcon icon={["fas", "file-download"]} className="me-1" />}
              >
                <DataTable
                  ref={pendingInvoicesDataTableReference}
                  value={sortedPendingInvoices}
                  dataKey="invoice_number"
                  lazy
                  removableSort
                  paginator
                  rows={tableLazyState.rows}
                  rowsPerPageOptions={[25, 50, 100]}
                  paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  first={tableLazyState.first}
                  totalRecords={totalPendingRecordCount}
                  onPage={onPage}
                  onSort={onSort}
                  sortField={tableLazyState.sortField}
                  sortOrder={tableLazyState.sortOrder}
                  loading={loading}
                >
                  <Column field="invoice_number" header="Invoice" sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column field="supplier.legal_name" header="Supplier" sortable style={{ minWidth: "12rem" }}></Column>
                  <Column field="due_at" header="Due date" body={(rowData) => formatDate(rowData.due_at)} sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column field="expires_at" header="Expires At" body={(rowData) => formatDate(rowData.expires_at)} sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column field="amount" header="Original value" body={(rowData) => formatCurrency(rowData.amount)} sortable style={{ minWidth: "8rem" }}></Column>
                  <Column field="discount_amount" header="Discount" body={(rowData) => rowData.discount_amount ? formatCurrency(rowData.discount_amount) : ""} sortable style={{ minWidth: "8rem" }}></Column>
                  <Column field="anticipated_amount" header="Anticipated" body={(rowData) => rowData.anticipated_amount ? `${formatCurrency(rowData.anticipated_amount)} (${formatPercentage(rowData.percentage_requested)})` : ""} sortable style={{ minWidth: "8rem" }}></Column>
                  <Column body={(rowData) => FinancierRequestActionsBodyTemplate(pk, rowData, pendingInvoices)} bodyStyle={{ textAlign: "end" }} style={{ maxWidth: "22rem" }}></Column>
                </DataTable>
              </TabPanel>
              <TabPanel
                header="History"
                leftIcon={<FontAwesomeIcon icon={["fas", "file"]} className="me-1" />}
              >
                <DataTable
                  ref={pastInvoicesDataTableReference}
                  value={sortedPastInvoices}
                  dataKey="invoice_number"
                  lazy
                  removableSort
                  paginator
                  rows={tableLazyState.rows}
                  rowsPerPageOptions={[25, 50, 100]}
                  paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  first={tableLazyState.first}
                  totalRecords={totalPastRecordCount}
                  onPage={onPage}
                  onSort={onSort}
                  sortField={tableLazyState.sortField}
                  sortOrder={tableLazyState.sortOrder}
                  loading={loading}
                >
                  <Column field="invoice_number" header="Invoice" sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column field="supplier.legal_name" header="Supplier" sortable style={{ minWidth: "12rem" }}></Column>
                  <Column field="due_at" header="Due date" body={(rowData) => formatDate(rowData.due_at)} sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column field="amount" header="Original value" body={(rowData) => formatCurrency(rowData.amount)} sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column field="discount_amount" header="Discount" body={(rowData) => rowData.discount_amount ? formatCurrency(rowData.discount_amount) : ""} sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column field="anticipated_amount" header="Anticipated" body={(rowData) => rowData.anticipated_amount ? formatCurrency(rowData.anticipated_amount) : ""} sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column field="percentage_requested" header="% Requested" body={(rowData) => rowData.percentage_requested ? formatPercentage(rowData.percentage_requested) : ""} sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column body={(rowData) => FinancierViewActionsBodyTemplate(pk, rowData.pk)} style={{ maxWidth: "20rem" }}></Column>
                </DataTable>
              </TabPanel>
            </TabView>
            <div className="overlay-button mx-3">
              {rightToolbarOptionsTemplate()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <main>
        <div className="container my-4">
          <InvoiceContent />
        </div>
      </main>
    </>
  );
}

export default FinancierListProgramInvoice;
