function sortHelper(data, sortField, sortOrder = 1) {
  if (!sortField) {
    return [...data];
  }

  return [...data].sort((a, b) => {
    const getValue = (obj, field) => {
      return field.split(".").reduce((acc, key) => acc && acc[key], obj);
    };

    const valueA = getValue(a, sortField) ?? "";
    const valueB = getValue(b, sortField) ?? "";

    if (typeof valueA === "string" && typeof valueB === "string") {
      return sortOrder * valueA.localeCompare(valueB);
    }

    if (typeof valueA === "number" && typeof valueB === "number") {
      return sortOrder * (valueA - valueB);
    }

    const stringA = String(valueA).toLowerCase();
    const stringB = String(valueB).toLowerCase();
    return sortOrder * stringA.localeCompare(stringB);
  });
}

export default sortHelper;
