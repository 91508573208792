import { Tag } from "primereact/tag";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { camelizeWithSpaces } from "./camelizeWithSpaces.jsx";

formatStatus.propTypes = {
  statusFieldData: PropTypes.string,
};

function formatStatus(statusFieldData) {
  const getStatusInfo = (status) => {
    switch (status) {
      case "approved":
      case "active":
      case "paid":
        return {
          severity: "success",
          title: `${status.charAt(0).toUpperCase()}${status.slice(1)}`,
          icon: "check",
        };
      case "available":
        return {
          severity: "info",
          title: "Available",
          icon: "circle-play",
        };
      case "request_is_sent":
        return {
          severity: "info",
          title: "Request is sent",
          icon: "arrow-turn-up",
        };
      case "inactive":
        return {
          severity: "grey",
          title: "Inactive",
          icon: "xmark",
        };
      case "pending":
        return {
          severity: "info",
          title: "Pending",
          icon: "hourglass-start",
        };
      case "declined":
        return {
          severity: "danger",
          title: "Declined",
          icon: "circle-xmark",
        };
      case "past_due":
        return {
          severity: "danger",
          title: "Past Due",
          icon: "circle-xmark",
        };
      case "past_due_out_of_network":
        return {
          severity: "info",
          title: "Out of Network",
          icon: "arrows-split-up-and-left",
        };
      case "expired":
        return {
          severity: "info",
          title: "Expired",
          icon: "stopwatch",
        };
      default:
        return {
          severity: null,
          title: camelizeWithSpaces(status),
          icon: "question-circle",
        };
    }
  };

  const { severity, title, icon } = getStatusInfo(statusFieldData);

  return (
    <Tag
      icon={(options) => (
        <FontAwesomeIcon
          className={`status-icon ${severity}`}
          icon={["fas", icon]}
          {...options.iconProps}
        />
      )}
      value={title}
      severity={severity}
    ></Tag>
  );
}

export default formatStatus;
