import apiAxios from "../../../axios.js";
import { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import notificationStore from "../../../stores/NotificationStore.js";
import { isNotEmpty } from "../../helpers/isEmptyHelpers.jsx";
import { timers } from "../../constants.jsx";
import ShowAlert from "../../utils/Alert.jsx";

library.add(fas);


function ProfileOptionsBodyTemplate() {
  const clearNotifications = notificationStore((state) => state.clearNotifications);
  const unknownError = "An unknown error occurred";
  const menu = useRef(null);
  const [onLogoutSuccessCalled, setOnLogoutSuccessCalled] = useState(false);

  const items = [
    {
      label: "Logout",
      icon: "pi pi-logout",
      command: () => {
        logoutUser();
      }
    }
  ];

  const navigateToLogin = (doubleCallFun) => {
    if (onLogoutSuccessCalled) { return; }
    doubleCallFun(true);
    window.location.replace("/login");
  };

  const logoutUser = () => {
    apiAxios
      .delete(`${process.env.REACT_APP_BASE_API_URL}/api/v1/logout`)
      .then((response) => {
        const logoutResponse = response.data;
        if (isNotEmpty(logoutResponse)) {
          clearNotifications();
          localStorage.clear();
          ShowAlert("success", logoutResponse.message, () => { navigateToLogin(setOnLogoutSuccessCalled); });
          setTimeout(() => {
            navigateToLogin(setOnLogoutSuccessCalled);
          }, timers.SHORT_DELAY);
        } else {
          ShowAlert("error", logoutResponse?.error || unknownError);
        }
      })
      .catch((error) => {
        var errorResponse = error.response?.data;
        ShowAlert("error", errorResponse?.error || unknownError);
      });
  };

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <Button
        icon={(options) => <FontAwesomeIcon icon={["fas", "user"]}  {...options.iconProps} /> }
        onClick={(event) => menu.current.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
      />
    </>
  );
}

export default ProfileOptionsBodyTemplate;
