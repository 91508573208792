import { useParams, useNavigate } from "react-router-dom";
import userStore from "../../stores/UserStore.js";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import NotificationsBodyTemplate from "../tables/templates/NotificationsBodyTemplate.jsx";
import ProfileOptionsBodyTemplate from "../tables/templates/ProfileOptionsBodyTemplate.jsx";

library.add(fas);

const Header = () => {

  const navigate = useNavigate();
  const user = userStore((state) => state.user);
  const params = useParams();
  const hasParams = Object.keys(params).length > 0;

  let start = null;
  let items = [];
  if (user.userType !== "Administrator") {
    items = [
      {
        label: "Dashboard",
        url: `/${user.userType.toLowerCase()}`,
      },
    ];
  }
  
  if (hasParams) {
    start = (
      <div className="navbar-brand">
        <Button 
          icon={(options) => <FontAwesomeIcon icon={["fas", "arrow-left"]}  {...options.iconProps} /> } 
          outlined
          rounded
          label="Back"
          severity="dark"
          onClick={() => navigate(-1)}
        />
      </div>
    );
  } else {
    start = (
      <div className="navbar-brand">
        <img
          src="/images/swiftly-logo.png"
          alt="Logo"
          className="rounded-circle logo"
        />
      </div>
    );
  }

  const end = (
    <div className="d-flex">
      <NotificationsBodyTemplate />
      <div className="mx-2">
        <span className="fw-bold">{ user.fullName }</span>
        <br />
        <small>{ user.entityLegalName }</small>
      </div>
      <ProfileOptionsBodyTemplate />
    </div>
  );

  return (
    <div className="navheader">
      <Menubar model={items} start={start} end={end} className="d-flex justify-content-between container" />
    </div>
  );
};

export default Header;
