import PropTypes from "prop-types";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import formatPercentage from "../helpers/formatPercentage.jsx";
import formatCurrency from "../helpers/formatCurrency.jsx";
import { camelizeWithSpaces } from "../helpers/camelizeWithSpaces.jsx";

library.add(fas);

SupplierInvoiceBottomSection.propTypes = {
  invoice: PropTypes.object.isRequired,
  fee: PropTypes.object.isRequired,
};

function SupplierInvoiceBottomSection({ invoice, fee }) {
  const renderInvoiceStatusButton = (status) => {
    switch (status) {
      case "approved":
        return <Button
          className="p-button-wide"
          icon={(options) => <FontAwesomeIcon icon={["fas", "circle-check"]} {...options.iconProps} />}
          type="button"
          label={camelizeWithSpaces(status)}
          rounded
          severity="success"
        />;
      case "request_is_sent":
        return <Button
          className="p-button-wide"
          icon={(options) => <FontAwesomeIcon icon={["fas", "hourglass-start"]} {...options.iconProps} />}
          type="button"
          label={"PENDING"}
          rounded
          severity="info"
        />;
      case "declined":
        return <Button
          className="p-button-wide"
          icon={(options) => <FontAwesomeIcon icon={["fas", "times-circle"]} {...options.iconProps} />}
          type="button"
          label={camelizeWithSpaces(status)}
          rounded
          severity="danger"
        />;
      case "past_due":
      case "past_due_out_of_network":
        return <Button
          className="p-button-wide"
          icon={(options) => <FontAwesomeIcon icon={["fas", "clock"]} {...options.iconProps} />}
          type="button"
          label={camelizeWithSpaces(status)}
          rounded
          severity="secondary"
        />;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-3 mb-5">
        <div>
          <h6>Requested Amount</h6>
          <small>You can only make one early payment request for an invoice</small>
        </div>
        <div className="d-flex">
          <p className="bold-sm-light">{formatPercentage(parseInt(fee.percentage_requested))}</p>
          <p className="bold-sm-light">({formatCurrency(fee.expected_amount, invoice.currency)})</p>
        </div>
      </div>
      {renderInvoiceStatusButton(invoice.humanizedStatus)}
    </>
  );
};

export default SupplierInvoiceBottomSection;
