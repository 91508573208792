import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Calendar } from "primereact/calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addLocale } from "primereact/api";

DateFilter.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  setIsClearFilterVisible: PropTypes.func.isRequired,
};

export default function DateFilter({ onDateChange, setIsClearFilterVisible }) {
  const dateFilterReference = useRef(null);

  const DateFilterForm = () => {
    const [dates, setDates] = useState(null);

    addLocale("bw", {
      firstDayOfWeek: 0,
      showMonthAfterYear: true,
      dayNames: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      dayNamesShort: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      dayNamesMin: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: "Today",
      clear: "Clear"
    });

    const handleChange = (event) => {
      setDates(event.value);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      dates[1].setDate(dates[1].getDate() + 1); // Date selection is exclusive, so add 1 day to include the end date

      onDateChange(dates[0], dates[1]);
      setIsClearFilterVisible(true);
      dateFilterReference.current.hide();
    };

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="form date-filter-form p-3">
            <div className="form-row row mb-4">
              <div className="form-group col-12">
                <Calendar
                  name="date"
                  value={dates}
                  onChange={handleChange}
                  inline
                  selectionMode="range"
                  dateFormat="dd-mm-yy"
                  locale="bw"
                />
              </div>
            </div>
            <div className="form-row row px-2">
              <div className="form-group col-6 px-1">
                <Button
                  className="w-full"
                  label="Cancel"
                  size="small"
                  rounded
                  outlined
                  severity="grey"
                  onClick={(e) => {
                    e.preventDefault();
                    dateFilterReference.current.hide();
                  }}
                />
              </div>
              <div className="form-group col-6 px-1">
                <Button
                  className="w-full"
                  label="Show Results"
                  size="small"
                  rounded
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  return (
    <>
      <Button
        className="has-icon"
        label="Date"
        icon={(options) => <FontAwesomeIcon icon={["fas", "calendar"]}  {...options.iconProps} />}
        rounded
        outlined
        severity="grey"
        onClick={(e) => dateFilterReference.current.toggle(e)}
      />
      <OverlayPanel ref={dateFilterReference}>
        <DateFilterForm />
      </OverlayPanel>
    </>
  );
};
