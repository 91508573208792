import apiAxios from "../../../axios.js";
import { useRef } from "react";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { ScrollPanel } from "primereact/scrollpanel";
import { OverlayPanel } from "primereact/overlaypanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import notificationStore from "../../../stores/NotificationStore.js";
import NotificationCard from "../../cards/NotificationsCard.jsx";
import ShowAlert from "../../utils/Alert.jsx";
import { isNotEmpty } from "../../helpers/isEmptyHelpers.jsx";

library.add(fas);

function NotificationsBodyTemplate() {
  const notificationReference = useRef(null);
  const notifications = notificationStore((state) => state.notifications);
  const clearNotifications = notificationStore((state) => state.clearNotifications);
  const unknownError = "An unknown error occurred";

  const markNotificationsRead = () => {
    apiAxios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/notifications/read_all`)
      .then(() => {
        clearNotifications();
        ShowAlert("Notifications Read!", "All your notifications have been marked as read.");
      })
      .catch((error) => {
        ShowAlert("error", error.response?.data?.error || unknownError);
      });
  };

  const notificationList = () => {
    return (
      <>
        {notifications.map((notification) => {
          return (
            <NotificationCard
              key={notification.pk}
              notification={notification}
            />
          );
        })}
      </>
    );
  };
  
  return (
    <>
      <div className="position-relative">
        <Button
          icon={(options) => <FontAwesomeIcon icon={["fas", "bell"]}  {...options.iconProps} /> }
          className="mx-3"
          outlined
          severity="grey"
          onClick={(e) => notificationReference.current.toggle(e)} 
        />
        { isNotEmpty(notifications) &&
          <Badge className="p-badge-shoulder" value=""></Badge>
        }
      </div>
      <OverlayPanel ref={notificationReference}>
        <div className="notification-dropdown">
          {
            isNotEmpty(notifications) ? (
              <>
                <div className="d-flex justify-content-between">
                  <h5>Notifications<small> ({notifications.length})</small></h5>
                  <Button
                    className="p-text-small"
                    label="Mark all as read"
                    text
                    rounded
                    onClick={() => markNotificationsRead()}
                  />
                </div>
                <ScrollPanel style={{ maxHeight: "520px" }}>
                  {notificationList()}
                </ScrollPanel>
              </>
            ) : (
              <div className="text-center mt-2">
                <p>No notifications</p>
              </div>
            )
          }
        </div>
      </OverlayPanel>
    </>
  );
}

export default NotificationsBodyTemplate;
