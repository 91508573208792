import PropTypes from "prop-types";
import { InputTextarea } from "primereact/inputtextarea";

import formatCurrency from "../helpers/formatCurrency.jsx";
import SupplierInvoiceBottomSection from "./SupplierInvoiceBottomSection.jsx";
import SupplierInvoiceFee from "../utils/SupplierInvoiceFee.jsx";

import { getSelectedFee } from "../utils/FeeUtils.jsx";
import SupplierInvoiceTopSection from "./SupplierInvoiceTopSection.jsx";

DeclinedInvoiceSection.propTypes = {
  invoice: PropTypes.object,
};

function DeclinedInvoiceSection({ invoice }) {
  const selectedFee = getSelectedFee(invoice);
  return (
    <div className="card card-invoice p-0">
      <div className="card-header">
        <img src="/images/swiftly-logo.png" alt="Logo" className="logo-overlay" />
        <p className="m-0">The financier declined your discount request</p>
        <p className="bold-xxl">{formatCurrency(selectedFee.expected_amount, invoice.currency)}</p>
      </div>
      <div className="card-body">
        <div className="my-3">
          <h6>Decline reason</h6>
          {invoice.early_payment_request.decline_reason && (
            <InputTextarea
              value={invoice.early_payment_request.decline_reason}
              rows={4}
              disabled
              style={{ width: "100%" }}
            />
          )}
        </div>
        <SupplierInvoiceTopSection invoice={invoice} />
        <hr />
        <SupplierInvoiceFee invoice={invoice} earlyPaymentFees={selectedFee?.simulated_fee_list} />
        <SupplierInvoiceBottomSection invoice={invoice} fee={selectedFee} />
      </div>
    </div>
  );
};

export default DeclinedInvoiceSection;
