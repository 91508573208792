import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AdminProgramCrudActionsBodyTemplate(pk){
  const navigate = useNavigate();
  return (
    <>
      <Button 
        icon={(options) => <FontAwesomeIcon icon={["fas", "eye"]}  {...options.iconProps} /> } 
        outlined
        severity="grey" 
        onClick={() => navigate(`/admin/programs/${pk}`)}
      />
      <Button 
        icon={(options) => <FontAwesomeIcon icon={["fas", "pen-to-square"]}  {...options.iconProps} /> } 
        outlined 
        severity="grey" 
        onClick={() => navigate(`/admin/programs/${pk}/edit`)}
      />
    </>
  );
};

export default AdminProgramCrudActionsBodyTemplate;
