import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { timers } from "../constants.jsx";

async function ShowAlert(severity, message, onConfirm = () => {}) {
  let icon = "";
  let iconColour = "";

  switch (severity) {
    case "info":
      icon = "info";
      iconColour = "blue";
      break;
    case "success":
      icon = "success";
      iconColour = "green";
      break;
    case "warning":
      icon = "warning";
      iconColour = "orange";
      break;
    case "error":
      icon = "error";
      iconColour = "red";
      break;
    default:
      icon = "info";
      iconColour = "blue";
      break;
  }

  const result = await withReactContent(Swal).fire({
    title: severity.charAt(0).toUpperCase() + severity.slice(1),
    icon: icon,
    timer: timers.LONG_DELAY,
    iconColor: iconColour,
    buttonsStyling: false,
    backdrop: "rgba(0,0,0,0.4)",
    customClass: {
      confirmButton: "swal2-confirm-ok",
    },
    text: message,
  });
  if (result.isConfirmed) {
    onConfirm();
  }
}

export default ShowAlert;
